import React from "react";
import Icon from "../atom/Icon";
import Seprator from "../atom/Seprator";


const Header = ({text, className, iconeSec, icon, onNextClick}) => {
    return (
        <>
            <div
                className={`flex flex-row justify-between items-center  mt-36 px-24 mb-20 w-full ${className}`}>
                <p className=" text-DarkText3 text-16 font-medium">{text}</p>
                {/* <div onClick={onNextClick}
                     className={`w-32 h-27 bg-white_200 flex justify-center items-center rounded-lg ${iconeSec}`}>
                    <Icon iconName={icon}/>

                </div> */}
            </div>
            <Seprator/>
        </>
    );
};

export default Header;
