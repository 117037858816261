import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch from "../../utility/Helper";

class ReminderRepository {

    async getReminderList() {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("reminder/reminder")
            });

    }

    async getReminderItem({id}) {
        try {
            const response = await axiosInstance.get(`reminder/reminder/${id}`,)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async editReminder(reminderId, isActive) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.patch(`reminder/reminder/${reminderId}`,{
                    isActive:isActive
                })
            });

    }

    async deleteReminder(reminderId){
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.delete(`reminder/reminder/${reminderId}`)
            });
    }
    async addReminder(data) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post("reminder/reminder", data)
            });

    }

}

export default ReminderRepository;