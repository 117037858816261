import '../loading/Loading.css'

const HelpButton = ({title, onClick, className, isLoading}) => {

    return (

        <div
            className={`flex flex-col w-full h-36 justify-center items-center bg-blue rounded-[12px] text-white ${className} cursor-pointer`}
            onClick={!isLoading ? onClick : null}>

            {isLoading ? (
                <span className="loader1"></span>
            ) : (<p>{title}</p>)}
        </div>);
}

export default HelpButton;