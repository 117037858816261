import React, {createContext, useContext, useEffect, useState} from "react";
import HelpTimeOut, {timeout} from "../../../utility/Helper";
import ReportRepository from "../../../repository/report/ReportRepository";
import api from "js-cookie";

const JourneyContext = createContext();

export const JourneyProvider = ({children}) => {
    const [sliderItems, setSliderItems] = useState([]); // State for slider items
    const [selectedSliderItem, setSelectedSliderItem] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false)
    const reportRepository = new ReportRepository();
    const [emptyState, setEmptyState] = useState(false);
    const [journeyList, setJourneyList] = useState([]);
    const [selectedJourney, setSelectedJourney] = useState(1);
    const generateDateLabel = (offset) => {
        setLoading(true)
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const date = new Date();
        date.setDate(date.getDate() + offset);
        const dayName = days[date.getDay()];
        const dayNumber = date.getDate();
        const text = `${dayName} ${dayNumber}`;
        return {date: text, isActive: offset === 0, level: 0};
    };
    useEffect(() => {
        const initialSliderItems = [
            generateDateLabel(0),
            generateDateLabel(-1),
            generateDateLabel(-2),
            generateDateLabel(-3),
            generateDateLabel(-4),
            generateDateLabel(-5),
            generateDateLabel(-6),
            generateDateLabel(-7),
        ];

        setSliderItems(initialSliderItems);
        sendApiRequest().then(value => {
            console.log("FINISH API");
            if (journeyList === []) {
                setSelectedJourney(1);
            }
            for (const journey in journeyList) {
                console.log("FORRRR")
                if (journey.date === initialSliderItems.last) {
                    console.log("LASTTTT");
                    setSelectedJourney(journey.level);

                } else {
                    console.log("ONEEEEE");

                    setSelectedJourney(1);
                }
            }

        });

    }, []);

    const onSliderItemClick = (item) => {
        setSliderItems(prevState => prevState.map((prev) => {
            if (prev.date === item.date) {
                return {...prev, isActive: true}
            } else {
                return {...prev, isActive: false}
            }
        }));

        for (const journey in journeyList) {
            if (journey.date === item.date) {
                setSelectedJourney(journey.level);
                console.log("JOURNEY LEVEL : ",journey.level);
            } else {
                setSelectedJourney(1);
                console.log("JOURNEY LEVEL : 111");

            }
        }
    }

    async function sendApiRequest() {
        setLoading(true);
        const response = await reportRepository.getAllJourney()
        setLoading(false);
        if (response.success) {
            setJourneyList(response.data.map((journey) => {
                return {id: journey.id, date: journey.date, level: journey.level};
            }))
        }
        console.log("XXXXXXXXXXXXX")

    }


    const openModal = () => {
        setIsModalShow(true);
    }
    const closeModal = () => {
        setIsModalShow(false);
    }
    return (
        <JourneyContext.Provider value={{
            selectedJourney,
            onSliderItemClick,
            loading,
            setLoading,
            closeModal,
            isModalShow,
            openModal,
            journeyList,
            sliderItems
        }}>
            {children}
        </JourneyContext.Provider>
    );
}

export function JourneyUseContext() {
    return useContext(JourneyContext);
}
