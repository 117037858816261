import React from 'react'

const NoData = () => {
  return (
    <div className="h-24 bg flex items-center justify-center w-full border-orange backdrop-blur-sm bg-orange_200 text-DarkText3 text-12 rounded-2xl border-1">
    <p>No Data</p>
  </div>
  )
}

export default NoData