import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({barData}) => {
    if (barData.isEmpty) {
        return;
    }

    console.log("BAR DATA :", barData);

    const days = barData.map((d) => {
        console.log("D : ",d.Date);
        const date = new Date(d.Date);
        const dayIndex = date.getDay();
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysOfWeek[dayIndex];
    });
    console.log(days);
    const series = [
        {
            data: barData.map((d) => d["Average Feeling"]),
        },
    ];
    const options = {
        chart: {
            type: "bar",
            offsetX: -23,
            offsetY: -23
            // height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 7,
                borderRadiusApplication: "around",
                borderRadiusWhenStacked: "last",
                columnWidth: "16px", // Adjust this value to add space between columns
                // distributed: true,
                // barHeight: "122px",
                colors: {

                    backgroundBarColors: ["#F9F9F9"],
                    backgroundBarOpacity: 1,
                    backgroundBarRadius: 7,
                },

            },
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,

        },
        xaxis: {
            categories: days,
            labels: {
                show: true,

                style: {
                    colors: "#424874",
                    fontSize: "10px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    cssClass: "apexcharts-xaxis-label",
                },
            },
            axisBorder: {
                show: false,

            },
            axisTicks: {
                show: false,

            },


        },

        yaxis: {},

        fill: {
            opacity: 1,
            colors: "  #DEF7FF",
        },
        legend: {
            show: false
        }
    };


    return (
        <div className="chart-container">
            <div id="chart">
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={"120%"}
                    width={280}
                />
            </div>
        </div>
    );
};

export default BarChart;
