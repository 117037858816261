import React, {createContext, useEffect, useReducer, useState} from "react";

import ReportRepository from "../../../repository/report/ReportRepository";
import breath from "../breath/Breath";
import {useNavigate} from "react-router-dom";

const DepressionContext = createContext();

export const DepressionProvider = ({children}) => {
    const navigate = useNavigate();
    const reportRepository = new ReportRepository();
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)

    const [answers, setAnswers] = useState([])

    const onInitState = async () => {
        console.log("INIT STATE")
        setLoading(true)
        const response = await reportRepository.getDepressionAnswers();
        setLoading(false)
        console.log("RESPONSE DATA : ", response.data)
        const insertIsActiveList = response.data.map((item, index) => {
            return {...item, "isActive": false}
        })
        setAnswers(insertIsActiveList);

    }
    useEffect(() => {
        onInitState();
    }, []);


    const onItemClick = (item) => {
        setAnswers(prevState => {
            return prevState.map((prevItem) => {
                if (item.id === prevItem.id) {
                    return {...item, isActive: !item.isActive}
                } else {
                    return prevItem;
                }

            })
        })
    }
    const onDoneClick = async () => {
        const selectedAnswers = answers.filter((item) => item.isActive)
            .map(({isActive, ...keep}) => keep)
        let totalNumberOf2 = 0;
        let hasLevel3 = false;
        selectedAnswers.forEach(function (value) {
            if (value.level === 3) {
                hasLevel3 = true;
            } else if (value.level === 2) {
                totalNumberOf2++;
            }
        })
        setButtonLoading(true);
        const response = await reportRepository.sendDepressionAnswer(selectedAnswers);
        setButtonLoading(false);
        if (response.success) {
            if (totalNumberOf2 === 2 || hasLevel3) {
                navigate("/result", {state: "RDHome"})
            } else {
                navigate("/breath", {state: "RDCalm"})
            }
        }

    }


    return (
        <DepressionContext.Provider value={{answers, loading, onInitState, onItemClick, onDoneClick, buttonLoading}}>
            {children}
        </DepressionContext.Provider>)
}

export function DepressionUseContext() {
    return React.useContext(DepressionContext);
}
