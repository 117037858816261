import React from "react";
import logo from "../../../assets/photo/group.png";

import Icon from "../atom/Icon";

const Header = ({isUserLogin, onLoginClick, onSignupClick, onNotificationClick, onProfileClick, hasReminder}) => {


    return (
        <div className="flex flex-row items-center justify-between mt-24 h-46 mb-18 absolute left-24p right-24p z-1  ">
            <div className="-m-9 flex flex-row  items-center">
                <img src={logo} alt=""/>
                <span className="text-12 font-semibold text-DarkText -ml-3 mb-4">
          HelpFinity
        </span>
            </div>


            <div className="flex flex-row items-center text-DarkText3 text-10 font-medium gap-3">
                <div
                    className={`flex justify-center items-center  w-[26px] h-[26px]  bg-white shadow-blur1 rounded-md`}>
                    <Icon className={"cursor-pointer"}
                          iconName={`${hasReminder ? "notification 3" : "Notification 3 blue"}`}
                          onClick={onNotificationClick}/>
                </div>

                {isUserLogin ?
                    (<div className={"w-[40px] h-[40px] bg-white rounded-full flex  justify-center items-center "}>
                        <Icon iconName={"no-profile"} onClick={onProfileClick}
                        />
                    </div>) :
                    (<div className={"flex flex-row gap-2"}>
                        <button className="" onClick={onLoginClick}>Login</button>
                        <button className="" onClick={onSignupClick}>Sign up</button>
                    </div>)

                }


            </div>
        </div>);
};

export default Header;
