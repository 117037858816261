import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({pieData}) => {

    console.log("PIE : ",pieData);
    const values = pieData.map((data) => data.value);
    const labels = pieData.map((data) => data.label);
    const colors = pieData.map((data) => data.color);
    console.log("VALUESSSSSSSSSS : ", values);


    const options = {
        chart: {
            width: 600,
            type: 'pie',
        },
        stroke: {
            width: 0
        },
        labels: labels,
        colors: colors,
        plotOptions: {

            pie: {
                customScale: 1.01,
                expandOnClick: false,
                dataLabels: {
                    offset: -15,

                },

            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ["#FFFFFF"],
                fontSize: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "mediume",
            },
            formatter: function (val, opts) {
                return labels[opts.seriesIndex];
            },
            position: 'middle',
            dropShadow: {
                enabled: false
            }
        },
        legend: {
            show: false,
        },

    };

    return (
        <div id="chart">
            <ReactApexChart
                options={options}
                series={values}
                type="pie"
            />
        </div>
    );
};

export default PieChart;
