import Icon from "../../../components/atom/Icon";
import Seprator from "../../../components/atom/Seprator";
import React from "react";

const PodcastListItem = ({title, description, onPlayClick, imgSrc}) => {
    return (
        <>
            <div className={"flex flex-row w-full items-center my-8"}>
                <div className={"w-[54px] h-[54px] rounded-full flex justify-center items-center bg-[#F3F3F3] mr-16"}>
                    <img
                        src={imgSrc}
                        className={"object-contain block rounded-full "}
                        alt=""
                    />
                    {/*<Icon iconName={"no-profile"}/>*/}


                </div>
                <div className={"flex flex-col items-start text-DarkText flex-grow gap-4"}>
                    <p className={"text-12 font-semibold"}>{title}</p>
                    <p className={"text-10 font-normal"}>{description}</p>

                </div>
                <Icon iconName={"play"} className={"flex justify-center items-center"} onClick={onPlayClick}/>


            </div>
            <Seprator/>
        </>

    )
}
export default PodcastListItem;