import React, {useContext, useEffect, useState} from "react";
import Header from "../../components/section/Header";
import Slider from "../home/components/atom/Slider";
import RoadMap from "./components/RoadMap";
import PageLoader from "../../components/loading/PageLoader";
import MoodTrackModal from "../../components/modals/MoodTrackModal";
import {useNavigate} from "react-router-dom";
import {JourneyUseContext} from "./JourneyContext";
import JourneySlider from "./components/JourneySlider";

const JourneyScreen = () => {
    const {
        selectedJourney,
        onSliderItemClick,
        loading,
        closeModal,
        isModalShow,
        openModal,
        journeyList,
        sliderItems
    } = JourneyUseContext();
    const navigate = useNavigate();
    const className =
        "!h-47  rounded-2xl text-12 font-medium !inline-flex justify-center items-center flex-wrap text-center px-16 !w-13v";

    let content;
    if (loading) {
        content = <PageLoader/>
    } else {
        content = <>
            <Header text={"Journey"} className={"!px-0"} icon={"blueRight"}/>
            <div
                className="w-full h-74 bg-skyblue mt-16 rounded-2xl pr-15  pt-15 pb-17"
                dir="rtl">
                <JourneySlider
                    slideNumber={5.3}
                    className={className}
                    items={sliderItems}
                    onItemClick={onSliderItemClick}/>

            </div>
            <RoadMap step={selectedJourney} openModal={openModal}/>
            <MoodTrackModal
                isOpen={isModalShow}
                onClose={closeModal}
                onEmotionClick={() => {
                    navigate(`/mood`);
                }}
                onBehaviorClick={() => {
                    navigate(`/behavior`);
                }}
            />
        </>
    }
    return (
        <>
            <div className="pt-55 pl-24 pr-24 left-0 right-0 bg-cream_300 h-100 absolute">
                {content}
            </div>
        </>
    );
};
export default JourneyScreen;
