import React, { useState } from "react";
import { useContext } from "react";
import { HomeUseStateContext } from "./HomeContext";
import Seprator from "../../components/atom/Seprator";
import QuickAccess from "./components/layout/QuickAccess";
import FeelingSec from "./components/layout/FeelingSec";
import PodcastSec from "./components/layout/PodcastSec";
import { Outlet, useNavigate } from "react-router-dom";
import MoodTrackModal from "../../components/modals/MoodTrackModal";
import { MainUseStateContext } from "../main/MainContext";
import toast from "react-hot-toast";
import { LOGIN_TOAST_MSG } from "../../../utility/constant";
import useAppNavigator from "../../../router/AppNavigator";
import AddReminderModal from "../reminder/AddReminderModal";
import { ReminderProvider } from "../reminder/ReminderContext";
import ReminderListModal from "../reminder/ReminderListModal";
import PageLoader from "../../components/loading/PageLoader";
import { Toaster } from "react-hot-toast";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import BASE_URL from "../../../utility/constant";
function HomeScreen() {
  const { data, podcastArrey,onInitialState, closeModal, isModalShow, loading } =
    HomeUseStateContext();
  const [currentAudioSrc, setCurrentAudioSrc] = useState("");
  const [isAudioVisible, setIsAudioVisible] = useState(true); // New state for audio visibility

  const handleItemClick = (item) => {
    setCurrentAudioSrc(item);
    setIsAudioVisible(true); // Ensure the audio section is visible when an item is clicked
    console.log(item);
  };

  const appNavigator = useAppNavigator();

  const sectionBox =
    "mt-24 rounded-2xl mb-24  border-1 border-solid border-orange pt-15 px-16 pb-16 bg-orange_200 blur1 backdrop-blur-12";

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className={"w-full h-74% flex flex-col justify-around mt-88"}>
          <QuickAccess />
          <Seprator />
          <FeelingSec sectionBox={sectionBox} />
          <Seprator />
          <PodcastSec
            sectionBox={sectionBox}
            onViewAllPodcastClick={() => {
              appNavigator.navigate("/home/podcast");
            }}
            handleItemClick={handleItemClick}
            podcastArrey={podcastArrey}
          />
          <MoodTrackModal
            isOpen={isModalShow}
            onClose={closeModal}
            onEmotionClick={() => {
              appNavigator.navigate(`/mood`);
            }}
            onBehaviorClick={() => {
              appNavigator.navigate(`/behavior`);
            }}
          />
        </div>
      )}
      <Toaster toastOptions={{ duration: 1000 }} />
      {currentAudioSrc && isAudioVisible && ( // Conditionally render the audio section
        <>
          <div className="mt-4 absolute bottom-0 z-50 w-full left-0">
            <AudioPlayer
              
              showFilledProgress={false}
              showFilledVolume={false}
              src={BASE_URL + currentAudioSrc}
              onPlay={() => console.log("Playing:", currentAudioSrc)}
            />
            
            <button
              className="  underline bg-DarkText3 py-2 text-12 text-white w-full"
              onClick={() => setIsAudioVisible(false)} // Hide audio section on click
            >
              Hide Audio Player
            </button>
          </div>
          <div>
            <p>Currently playing: {currentAudioSrc}</p>
          </div>
        </>
      )}
    </>
  );
}

export default HomeScreen;
