import toast from "react-hot-toast";

const HelpfinityTryCatch = async (method) => {

    try {
        const response = await method();

        const value = {
            success: true,
            // message: response.status.message,
            // statusCode: response.status.code,
            data: response.data
        };
        console.log("RESPONSE VALUE : ", value);

        return value;

    } catch (e) {

        console.log("CATCH ERROR Code : ", e.code);
        console.log("CATCH ERROR : ", e);
        if (e.code ===  99) {
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("access_token")
            // Cookies.remove("HTTP_ACCESS")
            console.log("GET FUCKED");
            toast.error("Please Login.", {id: "ERROR"});
            return window.location.replace("/home");

        }
        if (e.code === 11) {
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("access_token")
            // Cookies.remove("HTTP_ACCESS")
            console.log("GET FUCKED");
            toast.error("Please Login.", {id: "ERROR"});
            return window.location.replace("/home");
            console.log("CODE 11 Callled ......");

        }
        if(e.code === 22){
            console.log("CODE 22 Callled ......");

        }
        if(e.code === 33){
            console.log("CODE 33 Callled ......");
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("access_token")
            // Cookies.remove("HTTP_ACCESS")
            console.log("GET FUCKED");
            toast.error("Please Login.", {id: "ERROR"});
            return window.location.replace("/home");
        }
        if(e.code === 44){
            console.log("CODE 44 Callled ......");

        }
        toast.error(e.message, {id: "ERROR"});

        return {success: false, message: e.message};

    }
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default HelpfinityTryCatch;