import Icon from "../../../components/atom/Icon";
import React, {useContext, useEffect, useState} from "react";
import AiContext, {AiUseStateContext} from "../AiContext";
import './icons.css'

const ChangeQuestionButtons = () => {
    const {
        onPrevClick,
        onNextClick,
        currentQuestionIndex,
        questionTotalNumber,
        onNextScreenClick,



    } = AiUseStateContext();
    const [prevActive, setPrevActive] = useState(false)
    const [nextActive, setNextActive] = useState(true)


    useEffect(() => {

        if (currentQuestionIndex !== 0) {
            setPrevActive(true);
        } else {
            setPrevActive(false);
        }

        if (currentQuestionIndex === questionTotalNumber - 1) {
            setNextActive(false);
        } else {
            setNextActive(true);
        }
        // console.log("TOTAL : ", questionTotalNumber - 1);
        // console.log("CURRENTTT : ", currentQuestionIndex);

    }, [currentQuestionIndex]);


    return (
        <div className="flex flex-row justify-between w-full mt-auto items-center ">
            <div className="flex w-[45%] flex-col" onClick={onPrevClick}>
                <Icon iconName={"left 3"} className={`${prevActive ? "activeBtn" : "noActiveBtn"}`}/>

                <p className={`${prevActive ? "text-DarkText3" : "text-blue_100"} text-10 font-medium`}>previous
                    question</p>

            </div>
            <div className={"text-12 w-[10%] text-DarkText3 font-medium"}>
                <span>
                    <span>{currentQuestionIndex + 1}</span>
                    <span>/</span>
                    <span>{questionTotalNumber}</span>

                </span>
            </div>
            <div className="flex  w-[45%] flex-col items-end" onClick={nextActive ? onNextClick : onNextScreenClick}>
                <Icon iconName={"right 3"} className={"activeBtn"}/>
                <p className={`text-DarkText3 text-10 font-medium`}>
                    {nextActive ? "Next Question" : "Finish"}
                </p>
            </div>
        </div>
    )
}
export default ChangeQuestionButtons;