import React, {useEffect, useState} from "react";
import Header from "../../components/section/Header";
import HelpButton from "../../components/atom/HelpButton";
import {useLocation, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {BehaviorUseContext} from "./BehaviorContext";
import PageLoader from "../../components/loading/PageLoader";

const BehaviorScreen = () => {
    const {state, addCustomItem, seeResultClick, onItemClick, onShowAddCustomClick,setCustomAnswer,customAnswer} = BehaviorUseContext();
    let content;
    if (state.pageLoading) {
        content = (<PageLoader/>)
    } else {
        content = (
            <>
                <p className="pt-16 text-DarkText3 text-12 font-medium">
                    Which behavior do you feel in yourself?
                </p>
                <div className="grid grid-cols-2 gap-12 w-full px-24 pt-16 overflow-y-auto pb-24">
                    {state.items.map((item, index) => {
                            return (
                                <button
                                    key={item.id}
                                    className={`w-full text-center py-12 rounded-lg text-DarkText3 text-10 font-medium 
                            ${item.isActive ? "bg-blue_200" : "bg-cream_300"}`}
                                    onClick={() => onItemClick(item)}>
                                    {item.title}
                                </button>
                            );
                        }
                    )}
                </div>
            </>
        )
    }
    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-24 right-0 bg-cream_300 h-100 absolute">
            <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
                <Header text={"Behavior"} iconeSec={"!bg-input"} icon={"blueRight"}/>
                {content}
                {state.showAddNewItemForm && (
                    <div className="w-full px-24 pt-16">
                        <input
                            type="text"
                            value={customAnswer}
                            onChange={(e) => setCustomAnswer(e.target.value)}
                            className="w-full bg-cream_300 text-DarkText3 py-12 px-8 rounded-lg text-10 font-medium mb-8"
                            placeholder="Enter custom answer"
                        />
                        <HelpButton
                            className={"bg-skyblue text-white rounded-lg text-10 font-medium mb-8"}
                            onClick={() => {
                                addCustomItem(customAnswer)
                            }}
                            title={"Add Answer"}
                            isLoading={state.addCustomLoading}
                        />

                    </div>
                )}
                <div className="px-24 pb-24 w-full mt-auto text-12 font-semibold">
                    <HelpButton
                        title={"Create custom"}
                        className={"!bg-skyblue text-white mb-8"}
                        onClick={onShowAddCustomClick}
                    />
                    <HelpButton title={"Done"} onClick={seeResultClick} isLoading={state.seeResultLoading}/>
                </div>
            </div>
        </div>
    );
};

export default BehaviorScreen;
