import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch, {timeout} from "../../utility/Helper";
import emotions from "../../data/mock/Emotion.json";

class FeelingRepository {

    async getFeelingsList() {

        try {
            const response = await axiosInstance.get("feeling/feeling",)
            return response.data.data;
        } catch (e) {

        }
    }

    async getEmotionsList(mood) {
        const emotions = require("../../data/mock/Emotion.json");
        await timeout(500);
        let value;
        if (mood === "positive") {
            value = emotions.data[0];

        } else if (mood === "negative") {
            value = emotions.data[1];

        } else if (mood === "effect") {
            value = emotions.data[2];
        }
        return {
            "status": {
                "success": true,
                "message": "Har chi"
            },
            "data": value
        }


    }
    async sendEmotionAnswer(answers){
         return await HelpfinityTryCatch(function () {
            return axiosInstance.post("emotion/emotion", answers)
        })
    }

    async getFeelingsItem({id}) {
        try {
            const response = await axiosInstance.get(`feeling/feeling/${id}`,)
            return response.data.data;
        } catch (e) {

        }
    }

    async addFeeling({feeling}) {
        try {
            const response = await axiosInstance.post("feeling/feeling", {"feeling": feeling})
            return response.data.data;
        } catch (e) {

        }
    }

    async todayFeeling(mood) {

        await timeout(1000);
        return {
            "status": {
                "success": true,
                "message": "Har chi"
            },
        }
        /*return await HelpfinityTryCatch(function () {
            axiosInstance.post("feeling/mood", {
                feeling: mood
            })
        })*/
    }

}

export default FeelingRepository;