import { ReminderUseStateContext } from "./ReminderContext";
import React, { useEffect, useState } from "react";
import HelfinityModal from "../../components/modals/HelfinityModal";
import Icon from "../../components/atom/Icon";
import Input from "../../components/atom/Input";
import DaySelector from "./Components/DaySelector";
import TimeInput from "../../components/time-input/timeInput";
import HelpButton from "../../components/atom/HelpButton";
import ReminderItem from "./Components/ReminderItem";
import { useLocation, useNavigate } from "react-router-dom";
import { MainUseStateContext } from "../main/MainContext";
import Seprator from "../../components/atom/Seprator";

const ReminderListModal = ({ isOpen, onClose }) => {
    const {
        onChangeReminderClick,
        onDeleteReminderClick,
        mainDispatch,
        mainState,
    } = MainUseStateContext();

    const location = useLocation();
    const navigator = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const remindersPerPage = 4;

    // Calculate the total number of pages
    const totalPages = Math.ceil(mainState.reminderList.length / remindersPerPage);

    // Get the reminders for the current page
    const indexOfLastReminder = currentPage * remindersPerPage;
    const indexOfFirstReminder = indexOfLastReminder - remindersPerPage;
    const currentReminders = mainState.reminderList.slice(indexOfFirstReminder, indexOfLastReminder);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <HelfinityModal isOpen={isOpen} onClose={onClose}>
            <div className={"w-full flex flex-row justify-between mt-32 px-24p text-DarkText3"}>
                <p>Reminder</p>
                <Icon iconName={"Close"} onClick={onClose} className={"cursor-pointer"} />
            </div>

            <div className={"px-24p w-full flex flex-col items-start max-h-[80%] overflow-y-auto pt-10 pb-24"}>
                {currentReminders.map((item) => {
                    const days = item.day.map(item=> item).join(", ");
                    const days2 = `(${days})`
                    return (
                        <div key={item.id} className={"w-full"}>
                            <ReminderItem
                                title={item.title}
                                isActive={item.isActive}
                                description={days2}
                                onChange={() => {
                                    onChangeReminderClick(item);
                                }}
                                onDeleteClick={() => {
                                    onDeleteReminderClick(item);
                                }}
                            />
                            <Seprator />
                        </div>
                    )
                })}
            </div>

            <div className={"w-full flex flex-row justify-between items-center mt-4 absolute bottom-24p px-24"}>
                <button 
                    className={"px-2 py-1 bg-gray-300 rounded text-12 text-DarkText3"} 
                    onClick={handlePrevPage} 
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <p className={"text-12 font-medium text-black"}>
                    Page {currentPage} of {totalPages}
                </p>
                <button 
                    className={"px-2 py-1 bg-gray-300 rounded text-12 text-DarkText3"} 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            <div className={"flex flex-row justify-center items-center gap-2 cursor-pointer mt-4"}
                 onClick={() => {
                     navigator("/home/new-reminder")
                 }}>
                <Icon iconName={"Add"} />
                <p className={"text-12 font-medium text-DarkText3"}>Add reminder</p>
            </div>
        </HelfinityModal>
    )
}
export default ReminderListModal;
