import HelfinityModal from "../../components/modals/HelfinityModal";
import Icon from "../../components/atom/Icon";
import Input from "../../components/atom/Input";
import React, {useEffect, useState} from "react";
import DaySelector from "./Components/DaySelector";
import TimeInput from "../../components/time-input/timeInput";
import HelpButton from "../../components/atom/HelpButton";
import {MainUseStateContext} from "../main/MainContext";

const AddReminderModal = ({isOpen, onClose}) => {
    const [reminderTitle, setReminderTitle] = useState("")
    const {
        onTimeChangeHandler,
        onAddReminderBtnClick,
        mainDispatch,
        mainState,
    } = MainUseStateContext();



    return (

        <HelfinityModal isOpen={isOpen} onClose={onClose}>

            <div className={"w-full  flex flex-row justify-between mt-32 px-24p text-DarkText3"}>
                <p>Reminder</p>
                <Icon iconName={"Close"} onClick={onClose} className={"cursor-pointer"}/>

            </div>

            <div className={"px-24p w-full h-full flex flex-col justify-between items-start"}>
                <div className={"w-full"}>
                    <Input placeHolder={"Title"} className={"mt-[30px] mb-15p text-DarkText3 "}
                           onChange={(event) => {
                               setReminderTitle(event.target.value)
                           }}
                    />
                    <DaySelector onChange={(list) => {
                        let newList = list
                            .filter(item => item.isActive)
                            .map(item => item.day);
                        mainDispatch({type: "SET_LIST_OF_DAYS", payload: newList})

                    }}/>
                    <div className={"w-full h-[72px] bg-input rounded-lg mt-15p flex justify-center items-center"}>
                        <TimeInput
                            name="example"
                            initTime={mainState.selectedTime}
                            className='border-none hover:border-none bg-input cursor-pointer outline-none text-DarkText3 text-center'
                            onTimeChange={onTimeChangeHandler}

                        />
                    </div>
                </div>

                <HelpButton title={"Save"} className={"mb-24p"} onClick={() => {
                    onAddReminderBtnClick(reminderTitle);
                }}
                            isLoading={mainState.addReminderLoading}
                />
            </div>


        </HelfinityModal>
    )
}
export default AddReminderModal;