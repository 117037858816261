import {useEffect, useState} from "react";

const DaySelector = ({onChange}) => {
    const [dayList, setDayList] = useState([
        {
            day: "Mon",
            isActive: true
        },
        {
            day: "Sun",
            isActive: false
        },

        {
            day: "Tue",
            isActive: false
        },
        {
            day: "Wed",
            isActive: false
        },
        {
            day: "Thu",
            isActive: false
        },
        {
            day: "Fri",
            isActive: false
        },
        {
            day: "Sat",
            isActive: false
        },
    ])

    useEffect(() => {
        onChange(dayList);
    }, [dayList]);
    return (
        <div className={"flex flex-row justify-between bg-input rounded-lg w-full h-36 items-center px-20 cursor-pointer"}>
            {dayList.map((item, number) => {
                    return (
                        <p
                            onClick={() => {
                                setDayList((prevValue) => {
                                    return prevValue.map((prevItem) => {
                                        if (item.day === prevItem.day) {
                                            return {...prevItem, isActive: !item.isActive}
                                        } else {
                                            return prevItem;
                                        }
                                    })
                                })
                            }}

                            key={number} className={`font-medium text-10 text-DarkText3 ${item.isActive && "text-orange"}`}>
                            {item.day}
                        </p>

                    )
                }
            )}
        </div>
    )
}
export default DaySelector;