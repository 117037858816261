import React, {useEffect} from "react";
import Header from "../../components/section/Header";
import Icon from "../../components/atom/Icon";
import BarChart from "./components/BarChart";
import BubbleChart from "./components/BubbleChart";
import PieChart from "./components/PieChart";
import AnalyzeContext, {AnalyzeUseStateContext} from "./AnalyzeContext";
import PageLoader from "../../components/loading/PageLoader";
import NoData from "./components/NoData";

const Analyze = () => {
    const {initState, chartData, pageLoading, getBubbleChartData} = AnalyzeUseStateContext();

    let positiveEmotions;
    let negativeEmotions;
    let pieChart;
    let barChart;
    if (chartData != null) {
        positiveEmotions = getBubbleChartData(chartData.top_positive);
        negativeEmotions = getBubbleChartData(chartData.top_negative);
        pieChart = chartData.top_behavior;
        barChart = chartData.mood;
    }

    console.log("BAR CHART :", positiveEmotions);
    if (pageLoading) {
        return <PageLoader/>;
    }

    return (
        <div className="pt-55 pl-24 pr-24 left-0 right-0 bg-cream_300 h-100 absolute overflow-y-hidden">
            <div className="border-b-[112px] border-solid border-cream_300 h-100 mt-16">
                <div
                    className={
                        "flex flex-col  h-100 overflow-y-scroll  last:mb-[600px] "
                    }>
                    <Header text={"Analyze"} className={"!px-0"} icon={"blueRight"}/>
                    <div className="w-full shadow-blur1 rounded-3xl bg-white  mt-15 flex flex-col px-24  pt-16 pb-12 ">
                        <p className="text-DarkText3 text-12 font-medium mb-8">
                            Mood chart
                        </p>
                        {barChart.length > 0 ? (
                            <>
                                <div className="flex flex-row  ">
                                    <div className="w-auto flex flex-col justify-start mt-14 items-start gap-14 ">
                                        <Icon iconName={"excelentSm"}/>
                                        <Icon iconName={"goodSm"}/>
                                        <Icon iconName={"awfulSm"}/>
                                        <Icon iconName={"sadSm"}/>
                                    </div>
                                    <div className="">
                                        <BarChart barData={barChart}/>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <NoData/>
                            </>
                        )}
                    </div>
                    <div className="w-full  rounded-3xl bg-white  mt-24 flex flex-col   pt-16 max-h-1/3">
                        <p className="text-DarkText3 text-12 font-medium mb-8 pl-24">
                            Top positive emotion
                        </p>
                        {positiveEmotions.length > 2 ? (
                            <BubbleChart
                                data={positiveEmotions}
                                color={"#DBE2EF"}
                                textColor={"#112D4E"}
                            />
                        ) : (
                            <>
                                <div className="mx-24 mb-12">
                                    <NoData/>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="w-full  rounded-3xl bg-white  mt-24 flex flex-col  pt-16 max-h-1/3">
                        <p className="text-DarkText3 text-12 font-medium mb-8 pl-24">
                            Top negative emotion
                        </p>
                        {negativeEmotions.length > 2 ? (
                            <BubbleChart
                                data={negativeEmotions}
                                color={"#E7703D"}
                                textColor={"#FFFFFF"}
                            />
                        ) : (
                            <div className="mx-24 mb-12">
                                <NoData/>
                            </div>
                        )}
                    </div>

                    <div className="w-full  rounded-3xl bg-white  mt-24 flex flex-col px-24  pt-16 ">
                        <p className="text-DarkText3 text-12 font-medium mb-8">
                            Top behavior
                        </p>
                        {pieChart.length > 0 ? (<PieChart pieData={pieChart}/>) : (<>
                            <div className="mb-12"><NoData/></div>
                        </>)}

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Analyze;
