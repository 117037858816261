import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch, {timeout} from "../../utility/Helper";
import behaviorJson from "../../data/mock/behavior.json";

class BehaviorRepository {

    async getBehaviorList() {
        const behaviorJson = require('../../data/mock/behavior.json');
        await timeout(500);
        return behaviorJson;

        // return await HelpfinityTryCatch(
        //     function () {
        //         return axiosInstance().get("behavior/behavior-list");
        //     }
        // )

    }

    async addCustomBehavior(behavior) {
        console.log("BEHAVIORRRRRR : ", behavior);
        const custom = {
            "id": 9,
            "title": behavior,
        }
        await timeout(1000);
        return {
            "status": {
                "success": true,
                "message": "Har chi"
            },
            "data": custom
        }
        // return await HelpfinityTryCatch(
        //     function () {
        //         return axiosInstance().post("behavior/behavior-list", {"behavior": behavior});
        //     }
        // )

    }

    async userBehaviors(behaviorList) {
        console.log("SET BEHAVIORS LIST : ",behaviorList);
        await timeout(1000);
        return {
            "status": {
                "success": true,
                "message": "Har chi"
            },
        }
        // return await HelpfinityTryCatch(
        //     function () {
        //         return axiosInstance().post("behavior/user-behavior", {"behavior": behavior});
        //     }
        // )

    }

}

export default BehaviorRepository;