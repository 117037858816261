import React, {createContext, useEffect, useState} from "react";
import FeelingRepository from "../../../repository/feeling/FeelingRepository";
import {useNavigate} from 'react-router-dom';
import {timeout} from "../../../utility/Helper";

const MoodContext = createContext();

const MoodProvider = ({children}) => {
    const feelingRepository = new FeelingRepository();

    const [value, setValue] = useState(0);
    const [loading, setloading] = useState(false);
    const [iconName, setIconName] = useState("");
    const [feel, setFeel] = useState("");
    const [bgColor, setbgColor] = useState("");
    const [nextBtnLoading, setNextBtnLoading] = useState(false)

    const navigate = useNavigate();
    useEffect(() => {
        if (value <= 25) {
            setIconName("sadEmoji");
            setFeel("Sad");
            setbgColor("bg-blue_100");
        } else if (25 < value && value <= 50) {
            setIconName("amful");
            setFeel("Awful");
            setbgColor("bg-orange_100");
        } else if (50 < value && value <= 75) {
            setIconName("good");
            setFeel("Good");
            setbgColor("bg-pink_100");
        } else if (75 < value) {
            setIconName("soGood");
            setFeel("excellent");
            setbgColor("bg-yellow_100");
        }
    }, [value]);


    const onchangeSlider = (v) => {
        setValue(v);
    };
    const onNextButtonClick = async () => {
        console.log(feel);
        let param
        if (feel === "Sad" || feel === "Awful") {
            param = "negative";
        } else if (feel === "Good" || feel === "excellent") {
            param = "positive";
        }
        setNextBtnLoading(true)
        // const response = await feelingRepository.todayFeeling(feel.toLowerCase());
        await timeout(200);
        setNextBtnLoading(false)
        /*CHECK SUCCESS AND THEN GO TO NEXT*/
        ///SEND FELL TO SERVER
        navigate('/mood/question', {state: {mood: param, feel: feel}});

    }

    return (
        <MoodContext.Provider
            value={{value, onchangeSlider, bgColor, iconName, loading, feel, onNextButtonClick, nextBtnLoading}}>
            {children}
        </MoodContext.Provider>
    );
};

export default MoodContext;
export {MoodProvider};