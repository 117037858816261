import Header from "../../components/bottom-navigation/Header";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import BottomNavigation from "../../components/bottom-navigation/BottomNavigation";
import {MainUseStateContext} from "./MainContext";
import LoginModal from "../auth/LoginModal";
import SignupModal from "../auth/SignupModal";
import {Toaster} from "react-hot-toast";
import AppNavigator from "../../../router/AppNavigator";
import React, {useEffect} from "react";
import {ReminderProvider} from "../reminder/ReminderContext";
import ReminderListModal from "../reminder/ReminderListModal";
import AddReminderModal from "../reminder/AddReminderModal";
import RecoverPassModal from "../auth/RecoverPassModal";
import useAppNavigator from "../../../router/AppNavigator";

const MainScreen = () => {
    const {onInitialState, mainDispatch, mainState, reminderInitState} = MainUseStateContext();

    const location = useLocation();
    const navigator = useNavigate();
    const appNavigator = useAppNavigator();

    if (!mainDispatch || !mainState) {
        throw new Error("MainContext is not available");
    }

    const hideBottomNavRoutes = ["/mood/question", "/behavior", "/result", "/anxiety", "/depression", "/thought"];

    const shouldDisplayBottomNav = !hideBottomNavRoutes.includes(
        location.pathname
    );

    useEffect(() => {
        console.log("MAIN INIT ")
        onInitialState();
    }, []);
    return (
        <div className="h-full bg-HomePattern bg-no-repeat bg-cover px-24 flex flex-col">
            <Header
                isUserLogin={mainState.isUserLogin}
                onProfileClick={() => {
                    appNavigator.navigate("/profile")
                    navigator("/profile");
                }}
                onNotificationClick={async () => {
                    console.log("IS USER LOGIN ",mainState.isUserLogin);
                    appNavigator.navigate("/home/reminders")

                    if (mainState.isUserLogin) {
                        if (!mainState.getRemindersLoading) {
                            await reminderInitState();

                        }
                    }
                }}
                onLoginClick={() => {
                    navigator("/home/login");
                }}
                onSignupClick={() => {
                    navigator("/home/signup");
                }}
                hasReminder={mainState.hasReminder}
            />
            <Outlet/>

            <BottomNavigation shouldDisplay={shouldDisplayBottomNav}/>

            <AddReminderModal
                isOpen={location.pathname === "/home/new-reminder"}
                onClose={() => {
                    navigator("/home");
                }}
            />
            <ReminderListModal
                isOpen={location.pathname === "/home/reminders"}
                onClose={() => {
                    navigator("/home");
                }}
            />

            <LoginModal
                onClose={() => {
                    navigator("/home");
                }}
                isOpen={location.pathname === "/home/login"}
                switchToSignup={() => {
                    navigator("/home/signup");
                }}
            />

            <SignupModal
                onClose={() => {
                    navigator("/home");
                }}
                isOpen={location.pathname === "/home/signup"}
                switchToLogin={() => {
                    navigator("/home/login");
                }}
            />
            <RecoverPassModal
                onClose={() => {
                    navigator("/home");
                }}
                isOpen={location.pathname === "/home/RecoverPass"}/>

            <Toaster toastOptions={{duration: 2000}}/>
        </div>
    );
};
export default MainScreen;
