const HelfinityModal = ({isOpen, onClose, title, children, isClosable = true}) => {
    if (!isOpen) return null;

    return (
        <div className={
            `fixed flex top-0 left-0 bottom-0 right-0 
             w-full h-full bg-modalBg z-10`}>
            <div className={
                `absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2
                 flex flex-col h-[458px] w-[312px] bg-white rounded-[24px]
                 justify-start items-center z-20`}>

                {children}


            </div>
            <div className={"absolute bg-blur w-full h-full flex justify-center items-center z-10 backdrop-blur-[2px]"}
                 onClick={isClosable && onClose}
            />
        </div>
    )
}
export default HelfinityModal;