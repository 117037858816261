import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch, {timeout} from "../../utility/Helper";

class HomeRepository {
    async podcasts(){
        return await HelpfinityTryCatch(function(){
            return axiosInstance.get(`/landing`)
        })
    }
}
export default HomeRepository