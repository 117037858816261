import logo from "../../../assets/photo/group.png";
import Input from "../../components/atom/Input";
import Icon from "../../components/atom/Icon";
import HelpButton from "../../components/atom/HelpButton";
import HelfinityModal from "../../components/modals/HelfinityModal";
import React, { useState } from "react";
import { MainUseStateContext } from "../main/MainContext";

const SignupModal = ({ isOpen, onClose, switchToLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onSignUpBtnClick, googleSignup, mainState } = MainUseStateContext();

  return (
    <HelfinityModal isOpen={isOpen} onClose={onClose}>
      <div
        className={
          "flex flex-col justify-center items-center w-full px-[24px] pt-[40px] pb-[30px]"
        }>
        <div className="flex flex-row  items-center">
          <img src={logo} alt="" />
          <span className="text-16 font-semibold text-DarkText -ml-3 mb-4">
            HelpFinity
          </span>
        </div>

        <p className={"text-DarkText3 text-16 font-semibold mt-[32px]"}>
          Sign up
        </p>

        <Input
          placeHolder={"Email address"}
          className={"mt-[28px]"}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Input
          placeHolder={"Password"}
          className={"mt-[12px]"}
          type={"password"}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />

        <div
          className={
            "bg-input w-full h-36 flex flex-row rounded-lg items-center pl-[24px] mt-[80px]"
          }>
          <Icon iconName={"google-logo"} className={"mr-[12px]"} />
          <div
            className={"flex flex-col text-DarkText3 font-normal text-10"}
            onClick={googleSignup}>
            <p>Continue with google account</p>
          </div>
        </div>
        <HelpButton
          title={"Sign up"}
          className={"mt-[12px]"}
          onClick={() => {
            onSignUpBtnClick(email, password);
          }}
          isLoading={mainState.signupLoading}
        />

        <div
          className={
            "flex flex-row text-DarkText3 text-10 font-medium mt-[16px]"
          }>
          <span className={"pointer-events-none"}>
            Already have an account?
          </span>
          <span
            className={"font-semibold cursor-pointer ml-1"}
            onClick={switchToLogin}>
            Login
          </span>
        </div>
      </div>
    </HelfinityModal>
  );
};
export default SignupModal;
