import React, {useState} from "react";
import Picker from "react-mobile-picker";

const BreathDurationModal = ({isOpen, onClose, onSelectDuration}) => {
    // State for the selected value in the picker
    const [pickerValue, setPickerValue] = useState({duration: "1 Minute"});

    // Options for the picker
    const optionGroups = {
        duration: ["1 Minute", "5 Minutes"],
    };

    // Handle the start button click
    const handleSelectDuration = () => {
        const selectedValue = parseInt(pickerValue.duration.split(" ")[0], 10);
        console.log("Selected duration:", selectedValue);
        onSelectDuration(selectedValue);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center z-50">
            <div className="bg-white w-full p-6 shadow-2xl rounded-t-3xl">
                <h2 className="text-lg mt-20 text-DarkText3 font-semibold text-center mb-4">
                    Select Duration
                </h2>
                {/* Picker with wheel scrolling and custom render for selected item */}
                <Picker
                    value={pickerValue}
                    onChange={setPickerValue}
                    wheelMode="natural"
                >
                    <Picker.Column name="duration">
                        {optionGroups.duration.map((option) => (
                            <Picker.Item key={option} value={option}>
                                {({selected}) => (
                                    <div
                                        style={{
                                            color: selected ? "#A4BEEE" : "#424874",
                                            fontWeight: selected ? "bold" : "normal",
                                            fontSize: selected ? "18px" : "12px",
                                        }}
                                    >
                                        {option}
                                    </div>
                                )}
                            </Picker.Item>
                        ))}
                    </Picker.Column>
                </Picker>
                <button
                    onClick={handleSelectDuration}
                    className="w-full bg-blue-500 text-DarkText3 py-2 px-4 rounded mt-4"
                >
                    Start
                </button>
                <button
                    onClick={onClose}
                    className="w-full bg-gray-500 text-DarkText3 py-2 px-4 rounded mt-2"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default BreathDurationModal;
