import {createContext, useContext, useEffect, useReducer, useState} from "react";
import {BehaviorInitState, BehaviorReducer} from "./BehaviorReducer";
import BehaviorRepository from "../../../repository/behavior/BehaviorRepository";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

const BehaviorContext = createContext();

export const BehaviorProvider = ({children}) => {
    const navigate = useNavigate();
    const [customAnswer, setCustomAnswer] = useState("");

    const [state, dispatch] = useReducer(BehaviorReducer, BehaviorInitState)
    const repository = new BehaviorRepository();
    const [maxId, setMaxId] = useState(0)
    const initState = async () => {
        console.log("INIT STATE")

        dispatch({type: "CHANGE_PAGE_LOADING"})
        const response = await repository.getBehaviorList();
        const behavior = response.data.behaviors[response.data.behaviors.length - 1];
        console.log("LAST CHILD : ", typeof behavior.id);
        setMaxId(behavior.id + 1);

        dispatch({type: "CHANGE_PAGE_LOADING"})
        dispatch({type: "SET_ITEMS", payload: response.data.behaviors})

    }
    useEffect(() => {
        initState()
    }, []);

    const addCustomItem = async (behaviorTitle) => {
        if (behaviorTitle == "") {
            toast.error("Enter Title.");

            return;
        }
        // dispatch({type: "CHANGE_ADD_CUSTOM_LOADING"})
        const data = {
            id: maxId,
            title: behaviorTitle,
            isActive: false
        }

        // const response = await repository.addCustomBehavior(behaviorTitle);
        // dispatch({type: "CHANGE_ADD_CUSTOM_LOADING"})
        // const item = {...response.data, isActive: false}
        // console.log("ITEM : ", data);

        setMaxId(prevState => prevState + 1);
        setCustomAnswer("");
        dispatch({type: "ADD_CUSTOM_ANSWER", payload: data})
        dispatch({type: "CHANGE_FORM_VISIBILITY"})

    }

    function createOutputObjectWithList(list) {
        const activeList = list.filter((answer) => {
            return answer.isActive;
        })
        return activeList.flatMap(item => item.title);

    }

    const seeResultClick = async () => {
        const answers = createOutputObjectWithList(state.items);
        if (answers.length == 0) {
            toast.error("Select at least One")
            return;
        }
        dispatch({type: "CHANGE_RESULT_LOADING"})

        const response = await repository.userBehaviors(answers);
        console.log("ANSWERS : ", answers)
        dispatch({type: "CHANGE_RESULT_LOADING"})

        navigate(-1);
        toast.success("changes saved successfully!");
    }
    const onItemClick = (item) => {
        dispatch({type: "CHANGE_ANSWER", payload: item})
    }
    const onShowAddCustomClick = () => {
        if (!state.pageLoading) {
            dispatch({type: "CHANGE_FORM_VISIBILITY"})
        }

    }
    return (<BehaviorContext.Provider
        value={{state, addCustomItem, seeResultClick, onItemClick, onShowAddCustomClick,setCustomAnswer,customAnswer}}>
        {children}

    </BehaviorContext.Provider>)
}

export function BehaviorUseContext() {
    return useContext(BehaviorContext);
}
