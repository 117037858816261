import HelfinityModal from "../../components/modals/HelfinityModal";
import Icon from "../../components/atom/Icon";
import logo from "../../../assets/photo/group.png";
import React, { useState } from "react";
import Input from "../../components/atom/Input";
import HelpButton from "../../components/atom/HelpButton";
import { MainUseStateContext } from "../main/MainContext";


const RecoverPassModal = ({isOpen, onClose}) => {
    const [email, setEmail] = useState("");
  return (
<></>
  )
}

export default RecoverPassModal