import React from "react";
import ReactApexChart from "react-apexcharts";

const BubbleChart = ({data, color, textColor}) => {


    const options = {
        colors: [color],
        chart: {
            height: 'auto',
            type: "bubble",
            offsetX: -23,
            offsetY: -33,
        },
        grid: {
            show: false,
        },
        plotOptions: {
            bubble: {
                zScaling: false,
                maxSize: 50,
            },
        },
        fill: {
            colors: ["#DBE2EF"],
        },
        tooltip: {enabled: false},
        dataLabels: {
            enabled: true,
            style: {
                colors: [textColor],
                fontSize: "10px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "bold",
            },
            formatter: function (val, opt) {
                const label =
                    opt?.w?.config?.series?.[opt.seriesIndex]?.data?.[opt.dataPointIndex]
                        ?.label;
                return label !== undefined ? label : "";
            },
        },
        series: [
            {
                name: "Bubble Chart",
                data: data,
            },
        ],
        xaxis: {
            tickAmount: 0,
            axisBorder: {
                show: false,

            },
            labels: {
                show: false
            },
            axisTicks: {
                show: false,

            },

        },
        yaxis: {
            tickAmount: 5,
        },
    };

    return (
        <div className="flex justify-center items-center w-full h-100">
            <div className="bubble-chart max-w-[900px] w-[100%]">
                <ReactApexChart
                    options={options}
                    series={options.series}
                    type="bubble"
                    height={'auto'}
                    // width={"auto"}
                />
            </div>
        </div>

    );
};

export default BubbleChart;
