import React, {useContext, useEffect} from "react";
import Header from "../../components/section/Header";
import Icon from "../../components/atom/Icon";
import AiContext, {AiUseStateContext} from "./AiContext";
import QuestionSection from "./components/QuestionSection";
import ChangeQuestionButtons from "./components/ChangeQuestionButtons";
import PageLoader from "../../components/loading/PageLoader";

const AiScreen = () => {
    const {
        initState,
        questionList,
        currentQuestionIndex,
        screenLoading,
        onAnswerClick,
        answersList,
        onNextScreenClick,
        questionTotalNumber
    } = AiUseStateContext();

    useEffect(() => {
        initState();
    }, []);


    if (screenLoading) {
        return (<PageLoader/>)
    }
    let content;

    if (questionTotalNumber === 0 || !questionTotalNumber) {
        content = (
            <div className={"flex justify-center items-center self-center"}>
                <p>There is No Question Yet....</p>
            </div>
        )
    } else {
        let selectedQuestion = questionList[currentQuestionIndex];
        const currentAnswer = answersList[currentQuestionIndex];

        if (currentAnswer) {
            selectedQuestion = {...selectedQuestion, currentAnswer};
        }
        content = (
            <>
                <QuestionSection
                    question={selectedQuestion}
                    onAnswerClick={onAnswerClick}

                />

                <ChangeQuestionButtons/>
            </>
        )
    }
    return (<div className="pt-88 pl-24 pr-24 left-0 pb-107 right-0 bg-cream_300 h-100 absolute">
        <div
            className={`bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative`}>
            <Header text={"Ai refer"} iconeSec={"!bg-input"} icon={"blueRight"} onNextClick={onNextScreenClick}/>
            <div className="w-full px-24 pb-24 flex flex-col h-100 justify-center">
                {content}
            </div>
        </div>
    </div>);
};

export default AiScreen;
