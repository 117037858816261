import axios from 'axios'; // برای کار با کوکی‌ها
import Cookies from 'js-cookie';

const BASE_URL = require("../../utility/constant");

const axiosInstance = axios.create({
    baseURL: "http://5.161.144.222:8000",
    headers: {
        'Content-Type': 'application/json'
    },

    timeout: 15000,
    timeoutErrorMessage: "TimeOut",

});

axiosInstance.interceptors.request.use(
    (req) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        // const httpAccess = Cookies.get('HTTP_ACCESS');
        const accessToken = localStorage.getItem('access_token');
        req.headers['Authorization'] = `Bearer ${accessToken}`;


        console.log("REQUEST : ", req);

        return req;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => {
        console.log("RESPONSE : ", response);

        return response;
    },
    async (error) => {
        console.log("AXIOS ERROR PRINT : ", error);

        if (error.message === "Network Error" && !error.response) {
            console.log("Network error - make sure API is running!.")
        }

        // if (!error.code || !error.response.code) {
        //     throw new Error("Network Error. Please try again.");
        // }

        if (error.code === "ERR_NETWORK") {
            throwCustomError("Network Error. Please try again", 44);
        }
        if (error.code === 'ECONNABORTED') {
            throwCustomError("Request timed out. Please try again.", 44);
        }
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            console.log("ERRORRRRRRRRRRRR 401 CALLL");
            originalRequest._retry = true;
            try {
                await refreshToken();
                return axiosInstance(originalRequest);
            } catch (e) {
                if (e.message === "Network Error" && !e.response) {
                    throwCustomError("Network Error. Please try again.1", 11);
                }
                if (e.code === "ERR_NETWORK") {
                    throwCustomError("Network Error. Please try again.2", 22);
                }
                if (e.response.status === 401) {
                    throwCustomError("Network Error. Please try again.3", 33);
                }
                throw e;

            }

        } else {

            if (error.response.status === 500) {
                console.log("XXXXXXXXXXXXXXXXXXXXXXXX")
                throwCustomError("Request Failed Call Support.", 44);

                return;
            }

            if (error.response.data.errors[0]) {

                const errorMessage = error.response.data.errors[0] ?? 'An error occurred';

                throwCustomError(errorMessage);


            } else {
                throwCustomError("ERROR Catch");

            }
        }
    }
);

async function refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');
    // const httpAccess = Cookies.get('HTTP_ACCESS');

    if (!refreshToken || !accessToken) {

        throwCustomError("No refresh token available.Try Again", 99);

    }
    const response = await axios.post('http://5.161.144.222:8000/accounts/refresh-access', {
        refresh: refreshToken,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    console.log("REFRESH TOKEN ALL RESPONSE : ", response);

    if (response.status === 200) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        localStorage.setItem('refresh_token', response.data.data.refresh_token);
        localStorage.setItem('access_token', response.data.data.access_token);

    } else {
        console.log("REFRESH STATUS NOT 200");

        throwCustomError("Token refresh failed", 99);
    }

}

function throwCustomError(message, code) {
    const error = new Error(message);
    error.code = code;
    throw error;

}

export default axiosInstance;
