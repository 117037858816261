import React, {createContext, useState, useEffect} from 'react';
import UserRepository from "../../../repository/user/UserRepository";
import PodcastRepository from "../../../repository/podcast/PodcastRepository";

const PodcastContext = createContext();

export const PodcastProvider = ({children}) => {
    const userRepository = new UserRepository();
    const podcastRepository = new PodcastRepository();

    const [podcastList, setPodcastList] = useState([]);
    const [pageLoading, setPageLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);

    const [podcastCategoryItems, setPodcastCategoryItems] = useState([
        {id: 1, text: "Mental patterns", iconName: "groupMed", isActive: false},
        {id: 2, text: "Meditation", iconName: "goal", isActive: false},
        {id: 3, text: "Sleep", iconName: "sleep", isActive: false},
        {id: 4, text: "Reframe stress", iconName: "groupCloud", isActive: false},
        {id: 5, text: " Introduction to mental health", iconName: "groupCloud", isActive: false},
    ])
    const [filteredPodcastList, setFilteredPodcastList] = useState([])
    const onInitialState = async () => {
        setPageLoading(true);
        const categoryListResponse = await podcastRepository.categories();
        const response = await podcastRepository.podcastsList();
        setPageLoading(false);
        if (categoryListResponse.success && response.success) {
            setPodcastCategoryItems(categoryListResponse.data.map((category) => {
                return {...category, isActive: false};
            }));
            setPodcastList(response.data);
        }

    };
    useEffect(() => {
        console.log("SELECTED CATEGORY : ", selectedCategory);
        if (!selectedCategory) {
            setFilteredPodcastList(podcastList);
            return;
        }
        const filterList = podcastList.filter((podcast) => podcast.category === selectedCategory)
        console.log("FILTER LIST : ", filterList);
        setFilteredPodcastList(filterList)

    }, [podcastList, selectedCategory]);
    const onCategoryItemClick = (id, item) => {
        setPodcastCategoryItems(prevState => {
            return prevState.map((prevItem) => {
                if (prevItem.id === item.id) {
                    if (!item.isActive === true) {
                        setSelectedCategory(item.id)
                    } else {
                        setSelectedCategory(null)
                    }
                    return {...prevItem, isActive: !item.isActive}
                } else {
                    return {...prevItem, isActive: false}
                }
            })
        })

    }

    // Set the initial static data
    useEffect(() => {
        onInitialState();


    }, []);

    return (
        <PodcastContext.Provider
            value={{
                podcastCategoryItems,
                pageLoading,
                podcastList,
                onInitialState,
                onCategoryItemClick,
                filteredPodcastList
            }}>
            {children}
        </PodcastContext.Provider>
    );
};

function PodcastUseStateContext() {
    return React.useContext(PodcastContext);
}

export {PodcastUseStateContext};
export default PodcastProvider;
