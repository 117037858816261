import { NavLink, useLocation } from "react-router-dom";
import Icon from "../atom/Icon";
import toast from "react-hot-toast";
import { LOGIN_TOAST_MSG } from "../../../utility/constant";
import useAppNavigator from "../../../router/AppNavigator";

function BottomNavigation({ isUserLoggedIn, shouldDisplay }) {
    const navLink = "w-56 h-48 bg-blue flex items-center justify-center rounded-xl fill-svg-nav";
    const inactiveNavLink = "w-56 h-48 bg-gray flex items-center justify-center rounded-xl";
    const location = useLocation();
    const appNavigator = useAppNavigator();
    const isHomeSelected = location.pathname.startsWith("/home");
    const isItemSelected = (item) => location.pathname === item;
    const itemBottomNavigationClassName = (item) => location.pathname === item ? navLink : inactiveNavLink;
    const navigateToScreen = (path) => {
        if (!isItemSelected(path)) {
            appNavigator.navigate(path);
        }
    };
    // console.log(shouldDisplay)

    const bottomNavigationItems = [
        {
            id: 1,
            className: isHomeSelected ? navLink : inactiveNavLink,
            icon: "home 1",
            onClick: () => navigateToScreen("/home")
        },
        {
            id: 2,
            className: itemBottomNavigationClassName("/activity"),
            icon: "activity 1",
            onClick: () => navigateToScreen("/activity")
        },
        {
            id: 3,
            className: itemBottomNavigationClassName("/journey"),
            icon: "category",
            onClick: () => navigateToScreen("/journey")
        },
        {
            id: 4,
            className: itemBottomNavigationClassName("/profile"),
            icon: "profile 1",
            onClick: () => navigateToScreen("/profile")
        }
    ];

    if (!shouldDisplay) {
        return null;
    }

    return (
        <nav className={`
            text-center bottom-4 w-auto left-0 ml-24 mr-24 right-0 bg-cream flex rounded-2xl
            flex-row justify-between mt-auto px-20 py-8 shadow-nav 
            ${location.pathname === "/home" ? "backdrop-blur-80" : "bg-white"} fixed
        `}>
            {bottomNavigationItems.map((item) => (
                <div key={item.id} className={item.className} onClick={item.onClick}>
                    <Icon iconName={item.icon} />
                </div>
            ))}
        </nav>
    );
}

export default BottomNavigation;
