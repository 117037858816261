import {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FeelingRepository from "../../../repository/feeling/FeelingRepository";
import toast from "react-hot-toast";

const EmotionContext = createContext();

export const EmotionProvider = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const userInitMood = location.state?.mood || "positive";
    const userEnteredFeeling = location.state?.feel || "Good";

    const [param, setParam] = useState(userInitMood);
    const [pageLoading, setPageLoading] = useState(false)
    const [doneBtnLoading, setDoneBtnLoading] = useState(false)
    const [question, setQuestion] = useState("");
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState({positive: [], negative: [], effect: [],})
    const [firstListPageAnswers, setFirstListPageAnswers] = useState({})
    const repository = new FeelingRepository();
    useEffect(() => {
        getEmotionsList();
    }, [param]);

    const getEmotionsList = async () => {
        setPageLoading(true);
        setSelectedAnswer([]);
        const response = await repository.getEmotionsList(param);
        setQuestion(response.data.questionTitle);
        const listOfAnswersWithIsActive = response.data.answers.map((answer) => {
            return {...answer, "isActive": false}
        });
        setAnswers(listOfAnswersWithIsActive);
        setPageLoading(false);

    }
    const onAnswerClick = (answer) => {
        console.log("ANSWERRRR : ", answer);
        setAnswers(prevState => {
            const newList = prevState.map((prev) => {
                if (prev.id === answer.id) {
                    return {...prev, isActive: !prev.isActive}
                } else {
                    return prev;
                }
            })
            return newList;

        })
        // setSelectedAnswer((prev) => {
        //     const list = selectListType(prev);
        //     if (list.includes(answer)) {
        //         return list.filter((item) => item !== answer);
        //     } else {
        //         return [...list, answer];
        //     }
        // });

    }

    function createOutputObjectWithList(list, param) {
        const activeList = list.filter((answer) => {
            return answer.isActive;
        })

        const newList = activeList.flatMap(item => item.title);
        const result = JSON.stringify(newList);

        console.log(`NEW LIST :::::  `, newList);

        console.log(`NEWRESULT  :::::  `, result);

        return {
            [param]: result
        };
    }

    const onNextBtnClick = () => {
        const result = createOutputObjectWithList(answers, param);
        setFirstListPageAnswers(result);
        setParam("effect");

    }
    const onDoneBtnClick = async () => {
        setDoneBtnLoading(true)
        const result = createOutputObjectWithList(answers, param);

        const newObject = {
            "feeling": userEnteredFeeling.toLowerCase(),
            ...firstListPageAnswers,
            ...result
        }

        const response = await repository.sendEmotionAnswer(newObject);
        setDoneBtnLoading(false)
        if (response.success) {
            navigate(-2)
            toast.success("changes saved successfully!");
        } else {
            toast.error("Error");

        }
    }


    return (<EmotionContext.Provider
        value={{
            param,
            onAnswerClick,
            question,
            answers,
            pageLoading,
            doneBtnLoading,
            onNextBtnClick,
            onDoneBtnClick,
            selectedAnswer
        }}>
        {children}
    </EmotionContext.Provider>)
}

export function EmotionUseContext() {
    return useContext(EmotionContext);
}

