import React from "react";
import CircularSlider from "react-circular-slider-svg";
// import "./style.css";

const Slider = ({onchangeSlider,value}) => {
    //const { size, minValue, maxValue,  } = props;
    const handleValue = isNaN(value) ? 0 : value;
    return (
        <div className="moodSVg mt-32">
            <svg width="0" height="0"  style={{ filter: "drop-shadow(1px 2px 4px #42487433)" }}  >
                <defs>
                    <linearGradient
                        id="arcProgress"
                        x1="0%"
                        x2="0%"
                        //gradientTransform="rotate(0)"
                    >
                       
                        <stop offset="100%" stopColor="#FE763C" stopOpacity="1" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient
                        id="arcBg"
                        x1="100%"
                        x2="0%"
                        gradientTransform="rotate(0)"
                    >
                        <stop offset="0%" stopColor="#FE763C" stopOpacity="0.12" />
                        <stop offset="12.5%" stopColor="#FE763C" stopOpacity="0.12" />
                        <stop offset="25%" stopColor="#FE763C" stopOpacity=".12" />
                        <stop offset="37.5%" stopColor="#FE763C" stopOpacity="0.12" />
                        <stop offset="50%" stopColor="#FE763C" stopOpacity="0.38" />
                        <stop offset="62.5%" stopColor="#FE763C" stopOpacity="0.38" />
                        <stop offset="75%"  stopColor="#FE763C" stopOpacity="0.38" />
                        <stop offset="87.5%" stopColor="#FE763C" stopOpacity=".55" />
                        <stop offset="100%"  stopColor="#FE763C" stopOpacity="1" />
                    </linearGradient>
                </defs>
            </svg>
            <div className="">
            <CircularSlider
                size={440}
                trackWidth={10}
                minValue={0}
                maxValue={100}
                startAngle={135}
                endAngle={225}
                angleType={{
                    direction: "cw",
                    axis: "-y"
                }}
                handle1={{
                    value: handleValue,
                    onChange:(v) => onchangeSlider(v)
                }}
                arcColor="url(#arcProgress)"
                arcBackgroundColor="url(#arcBg)"
            />
            </div>
            
        </div>
    );
    
};

export default Slider
