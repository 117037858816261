import React from "react";
import AnswerButton from "./AnswerButton";

const QuestionSection = ({question, onAnswerClick}) => {

    // console.log("QUESTION : ",question);
    if (!question) {
        return <div></div>
    } else {
        return (<>
            <p className="text-12 text-DarkText3 leading-20 pt-12 text-center font-medium">
                {question.questions}
            </p>

            <div className="flex flex-row justify-center items-center  gap-12  w-full mt-32">
                {question.answers.map((answer, index) => {
                    return (
                        <React.Fragment key={index}>
                            <AnswerButton title={answer} onClick={() => {
                                onAnswerClick(answer);
                            }}
                                          currentAnswer={question.currentAnswer}


                            />
                        </React.Fragment>


                    )
                })}

            </div>
        </>)
    }


}
export default QuestionSection;