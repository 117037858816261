import React, {createContext, useEffect, useReducer, useState} from "react";
import UserRepository from "../../../repository/user/UserRepository";
import toast from "react-hot-toast";
import validator from "validator/es";
import {useNavigate} from "react-router-dom";
import ReminderRepository from "../../../repository/reminder/Reminder";
import {mainInitState, mainReducer, modalReducer} from "./MainReducer";
import useAppNavigator from "../../../router/AppNavigator";


const MainContext = createContext();

export const MainProvider = ({children}) => {
    const navigator = useNavigate();
    const userRepository = new UserRepository();
    const reminderRepository = new ReminderRepository();

    const [mainState, mainDispatch] = useReducer(mainReducer, mainInitState);


    const reminderInitState = async () => {
        // console.log("MAIN STATE IS USE LOGIN :", mainState.isUserLogin);
        if (!mainState.isUserLogin) {
            return;
        }
        const date = new Date();
        const nowHour = date.getHours();
        let nowMin = date.getMinutes() + 1;
        if (nowMin < 10) {
            nowMin = "" + 0 + nowMin;
        }
        mainDispatch({
            type: "SET_REMINDER_TIME",
            payload: nowHour + ":" + nowMin,
        });


        mainDispatch({type: "REMINDER_GET_LOADING", payload: true});
        const response = await reminderRepository.getReminderList();
        mainDispatch({type: "REMINDER_GET_LOADING", payload: false});

        if (response.success) {
            console.log("RESPONSE REMINDER LIST :", response);

            mainDispatch({
                type: "SET_REMINDER_LIST",
                payload: response.data,
            });
            if (response.data.isEmpty) {
                mainDispatch({type: "SET_HAS_REMINDER", payload: false});
            } else {
                mainDispatch({type: "SET_HAS_REMINDER", payload: true});
            }
        }

    }
    const loginSignUpInitState = () => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            mainDispatch({type: "USER_LOGIN", payload: true});
        } else {
            mainDispatch({type: "USER_LOGIN", payload: false});
        }

    }
    const onInitialState = async () => {
        loginSignUpInitState();

    };
    useEffect(() => {
        // if (mainState.isUserLogin) {
        //     reminderInitState();
        // }

    }, [mainState.isUserLogin]);
    const onSignUpBtnClick = async (email, pass) => {
        console.log("EMAIL : ", email);
        console.log("PASS : ", pass);
        if (!email || !pass) {
            toast.error("Please Enter Your password and Email");
            return;
        }
        if (!validator.isEmail(email)) {
            toast.error("Please Enter Correct Email");
            return;
        }
        mainDispatch({type: "SIGNUP_LOADING", payload: true});
        const response = await userRepository.register({
            email: email,
            password: pass,
        });
        mainDispatch({type: "SIGNUP_LOADING", payload: false});
        if (response.success) {
            mainDispatch({type: "USER_LOGIN", payload: true});
            toast.success("SignUp Success");
            navigator("/home");
        } else {
            mainDispatch({type: "USER_LOGIN", payload: false});
        }
    };

    const onLoginBtnClick = async (email, pass) => {
        console.log("EMAIL : ", email);
        if (!email || !pass) {
            toast.error("Please Enter Your password and Email");
            return;
        }
        if (!validator.isEmail(email)) {
            toast.error("Please Enter Correct Email");
            return;
        }
        mainDispatch({type: "LOGIN_LOADING", payload: true});
        const response = await userRepository.login({
            email: email,
            password: pass,
        });
        mainDispatch({type: "LOGIN_LOADING", payload: false});

        if (response.success) {
            mainDispatch({type: "USER_LOGIN", payload: true});
            toast.success("Login Success");
            navigator("/home");
        } else {
            mainDispatch({type: "USER_LOGIN", payload: false});
            console.log("RESPONSE NOT SUCCESSSSSSSSSSSSSSSSSSSSSSS");
            console.log(response.message);
        }
    };
    const onTimeChangeHandler = (val) => {
        if (val.length === 5) {
            mainDispatch({type: "SET_REMINDER_TIME", payload: val});
        }
    };
    const onAddReminderBtnClick = async (reminderTitle) => {
        const data = {
            "title": reminderTitle,
            "day": mainState.listOfDays,
            "time": mainState.selectedTime,
            "isActive": true
        };

        if (reminderTitle) {
            mainDispatch({type: "REMINDER_BTN_LOADING", payload: true});

            const response = await reminderRepository.addReminder(data);
            mainDispatch({type: "REMINDER_BTN_LOADING", payload: false});

            if (response.success) {
                console.log("RESPONSE SUCCESS", response);
                const prevState = mainState.reminderList;

                mainDispatch({
                    type: "SET_REMINDER_LIST",
                    payload: [...prevState, response.data],
                });
                navigator("/home/reminders")

            }


        } else {
            toast.error("Title Can't Empty");
        }

    };
    const onChangeReminderClick = async (item) => {

        const response = await reminderRepository.editReminder(item.id, !item.isActive);

        if (response.success) {
            mainDispatch({type: "CHANGE_REMINDER", payload: item});
        }

        /*setReminderList((prevState) => {
                return prevState.map((prevItem) => {
                    if (item.title === prevItem.title) {
                        return {...prevItem, isActive: !item.isActive};
                    } else {
                        return prevItem;
                    }
                })
            })*/
    };
    const onDeleteReminderClick = async (item) => {
        const response = await reminderRepository.deleteReminder(item.id);
        if (response.success) {
            mainDispatch({type: "DELETE_REMINDER", payload: item});

        }
    }
    const forgerPass = () => {
        navigator("/home/RecoverPass")
    };
    return (
        <MainContext.Provider
            value={{
                onSignUpBtnClick,
                onLoginBtnClick,
                onInitialState,
                mainDispatch,
                mainState,
                onTimeChangeHandler,
                onAddReminderBtnClick,
                onChangeReminderClick,
                onDeleteReminderClick,
                forgerPass,
                reminderInitState
            }}>
            {children}
        </MainContext.Provider>
    );
};

function MainUseStateContext() {
    return React.useContext(MainContext);
}

export {MainUseStateContext};
export default MainProvider;
