import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";

import {FreeMode} from "swiper/modules";
import Icon from "../../../../components/atom/Icon";

const Slider = ({items, className, boxShadowStyle, slideNumber, IconProp, onItemClick,play,podcast,textClass}) => {
    return (<>
            <div>
            <Swiper
                slidesPerView={slideNumber}
                spaceBetween={12}
                freeMode={true}
                pagination={false}
                modules={[FreeMode]}
                className={`mySwiper2`}
            >
                {items.map((item, index) => (
                    
                    <SwiperSlide
                        onClick={() => {
                            onItemClick(item.audioSrc, item);
                            // onItemClick(item.route);
                        }}

                        key={index} // Add key prop here
                        className={`bg-white cursor-pointer text-blue font-semibold ${className} ${item.isActive ? "text-orange" : ""}`}
                        style={boxShadowStyle}
                    >
                        {IconProp && (<>{play?(<Icon
                                iconName={item.iconName}
                                className={"justify-center items-center mr-5 ml-5"}
                                svgClass={"playSVG"}
                            />):(<Icon
                                iconName={item.iconName}
                                className={"justify-center items-center"}
                            />)}</>
                            
                            
                        )}
                        <p className={`text-10 ${textClass} `}>{item.text}</p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>

    </>
        
    );
};

export default Slider;
