import React from 'react'
import Icon from '../../../../components/atom/Icon'
import MoodBox from "../atom/MoodBox";
import {HomeUseStateContext} from "../../HomeContext";
import toast, {Toaster} from 'react-hot-toast';
import {MainUseStateContext} from "../../../main/MainContext";
import {LOGIN_TOAST_MSG} from "../../../../../utility/constant";
import {Link} from "react-router-dom";
import useAppNavigator from "../../../../../router/AppNavigator";

const FeelingSec = ({sectionBox}) => {
    const {openModal} = HomeUseStateContext();
    const appNavigator = useAppNavigator();
    const moodBoxList = [{
        id: 1,
        text: "Breath",
        onClick: () => {
            appNavigator.navigate("/breath");

        }
    }, {
        id: 2,
        text: "Mood track", onClick: openModal

    }, {
        id: 3, text: "Ai refer", onClick: () => {
            appNavigator.navigate("/ai");


        }
    }]
    return (<>
        <div className={sectionBox}>
            <div
                className="flex flex-row justify-center items-center w-fit gap-4 text-DarkText3 text-12 font-semibold mb-27 ">
                <Icon iconName={"heart 1"}/>
                <span>Lorem ipisum</span>
            </div>
            <div className="flex justify-between items-center gap-9">
                {moodBoxList.map((item) => {
                    return (<Link
                        key={item.id}
                        to={item.to}
                        onClick={item.onClick}
                        className={`
                                rounded-xl bg-gray_300 border-1 border-solid border-white
                                text-12 font-semibold text-darkBlue w-1/3 h-63 flex justify-center items-center
                                `}>
                        {item.text}
                    </Link>)
                })}

            </div>
        </div>
    </>);
};

export default FeelingSec;
