import {useNavigate} from "react-router-dom";

const useAppNavigator = () => {
    const navigate = useNavigate();

    /*Check If User Login Then navigate*/
    const navigateIfLoggedIn = (path) => {
        console.log("PATH : ", path);
        const access = localStorage.getItem('access_token');
        if (access) {
            navigate(path);
        } else {

            navigate("/home/login")
            console.log("User is not logged in");
        }
    };

    /*Simple Navigator (Never Used....)*/
    const appNavigate = (path) => {
        navigate(path);
    };

    return {
        navigate: navigateIfLoggedIn,
        appNavigate
    };
};
export default useAppNavigator;