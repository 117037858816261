import ReactSwitch from "react-switch";
import Icon from "../../../components/atom/Icon";
import "./style.css"

const ReminderItem = ({isActive, title,description,onChange,onDeleteClick}) => {
    return (
        <div  className={"flex flex-row  w-full mt-20 mb-15 items-center justify-between "}>
            <div className={"flex flex-col items-start gap-1"}>
                <p className={"text-12 font-medium text-DarkText3"}>{title}</p>
                <p className={"text-10 font-normal text-DarkText3 overflow-clip"}>{description}</p>

            </div>

            <div className={"flex flex-row items-center gap-1"}>
                <ReactSwitch
                    handleDiameter={24}
                    height={16}
                    width={50}
                    onHandleColor={"#FE763C" }
                    offHandleColor={"#DBE2EF"}
                    offColor={"#F9F9F9"}
                    // onClick={"#424874"}
                    onColor={"#424874"}
                    className={isActive ? 'switchActive' : "switchNotActive"}
                    activeBoxShadow={"none"}

                    checkedIcon={false}
                    uncheckedIcon={false}
                    checked={isActive}

                    onChange={onChange}

                />
                <Icon iconName={"Delete 2"} className={"cursor-pointer"} onClick={onDeleteClick}/>
            </div>
        </div>
    )
}
export default ReminderItem