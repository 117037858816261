import HelfinityModal from "../../components/modals/HelfinityModal";
import Icon from "../../components/atom/Icon";
import logo from "../../../assets/photo/group.png";
import React, { useState } from "react";
import Input from "../../components/atom/Input";
import HelpButton from "../../components/atom/HelpButton";
import { MainUseStateContext } from "../main/MainContext";
import RecoverPassModal from "./RecoverPassModal";

const LoginModal = ({ isOpen, onClose, switchToSignup }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onLoginBtnClick, googleSignup, mainState, forgerPass } =
    MainUseStateContext();

  return (
    <HelfinityModal isOpen={isOpen} onClose={onClose}>
      <div
        className={
          "flex flex-col justify-center items-center w-full px-[24px] pt-[40px] pb-[30px]"
        }>
        <div className="flex flex-row  items-center">
          <img src={logo} alt="" />
          <span className="text-16 font-semibold text-DarkText -ml-3 mb-4">
            HelpFinity
          </span>
        </div>
        <p className={"text-DarkText3 text-16 font-semibold mt-[32px]"}>
          Login
        </p>
        <p className={"text-secondColor text-12 font-semibold "}>
          Welcome back!
        </p>

        <Input
          placeHolder={"Email address"}
          className={"mt-[28px]"}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <Input
          placeHolder={"Password"}
          type={"password"}
          className={"mt-[12px]"}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />

        <button
          onClick={forgerPass}
          className="text-DarkText3 text-10 font-medium border-none border-orange-700 self-end mt-[12px]">
          Forget your password?
        </button>
        <div
          className={
            "bg-input w-full h-36 flex flex-row rounded-lg items-center pl-[24px] mt-[36px]"
          }>
          <Icon iconName={"google-logo"} className={"mr-[12px]"} />
          <div className={"flex flex-col text-DarkText3 font-normal text-10"}>
            <p>Continue as kimia</p>
            <p>Kimia@gmail.com</p>
          </div>
        </div>
        <HelpButton
          title={"Login"}
          className={"mt-[12px]"}
          onClick={() => {
            onLoginBtnClick(email, password);
          }}
          isLoading={mainState.loginLoading}
        />

        <div
          className={
            "flex flex-row text-DarkText3 text-10 font-medium mt-[16px]"
          }>
          <span className={"pointer-events-none"}>
            Dont you have an account?
          </span>
          <span
            className={"font-semibold cursor-pointer ml-1"}
            onClick={switchToSignup}>
            Sign up
          </span>
        </div>
      </div>

  
    </HelfinityModal>
  );
};
export default LoginModal;
