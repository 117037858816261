import axiosInstance from "../../services/remote/axios";
import questions from "../../data/mock/ai_refer.json";
import HelpfinityTryCatch from "../../utility/Helper";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class AiReferRepository {

    async getQuestionList() {
        // const questions = await require("../../data/mock/ai_refer.json");
        // await timeout(1000);
        //
        // return {success: true, message: "SUCCESS", data: questions};

        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("AIrefer/questions-list")
            }
        )


    }

    async addAnswer({questionNumber, answer}) {
        ///MeTi Inja Bayad List Ferestad ??????
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post("AIrefer/user-multi-answer")
            }
        )
    }


    async thoughts(thoughts) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post("AIrefer/thoughts", {
                    "thoughts": thoughts
                });
            }
        )

    }
    async aiResult() {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("AIrefer/result");
            }
        )

    }

}

export default AiReferRepository;