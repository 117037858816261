import React, {createContext, useEffect, useState} from "react";
import ReportRepository from "../../../repository/report/ReportRepository";

const AnalyzeContext = createContext();
const AnalyzeProvider = ({children}) => {
    const reportRepository = new ReportRepository();
    const [chartData, setChartData] = useState({
        mood: [],
        top_positive: [],
        top_negative: [],
        top_behavior: [],

    })
    const [pageLoading, setPageLoading] = useState(false)
    const initState = async () => {
        setPageLoading(true);
        const response = await reportRepository.getAnalyzeReport();
        setPageLoading(false);
        if (response.success) {
            setChartData(response.data);
        } else {
            ///ERROR
        }

    }
    useEffect(() => {
        initState();
    }, []);
    const getBubbleChartData = (data) => {
        let listOfValues = [
            {x: 0, y: 0, z: "0", label: ""}]

        for (let i = 0; i < data.length; i++) {
            listOfValues.push(data[i]);
        }
        listOfValues.push(
            {x: 100, y: 0, z: "0", label: ""},
        )

        return listOfValues;
    }

    return (<AnalyzeContext.Provider value={{initState, chartData, pageLoading, getBubbleChartData}}>
        {children}
    </AnalyzeContext.Provider>)
}

function AnalyzeUseStateContext() {
    return React.useContext(AnalyzeContext)
}

export {AnalyzeUseStateContext};

export default AnalyzeProvider;