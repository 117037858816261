import React, {useContext, useEffect, useState} from "react";
import MoodContext from "./MoodContext";
import Seprator from "../../components/atom/Seprator";
import Icon from "../../components/atom/Icon";
import EmojiSec from "./components/EmojiSec";
import Slider from "./components/FeelSlide";
import HelpButton from "../../components/atom/HelpButton";
import Header from "../../components/section/Header";
import PageLoader from "../../components/loading/PageLoader";

const emojiImages = {
    sadEmoji: require("../../../assets/photo/svg/sadEmoji.svg"),
    amful: require("../../../assets/photo/svg/amful.svg"),
    good: require("../../../assets/photo/svg/good.svg"),
    soGood: require("../../../assets/photo/svg/soGood.svg"),
};
const MoodScreen = () => {
    const {name, onchangeSlider, value,bgColor,loading,iconName,feel,nextBtnLoading,onNextButtonClick} = useContext(MoodContext);


    /*
    useEffect(() => {
        setloading(true);
        setTimeout(() => {
            // setloading(false);
        }, 2000);
    }, [iconName]);

    useEffect(() => {
        // Preload emoji images when component mounts
        Object.values(emojiImages).forEach((image) => {
            new Image().src = image.default;
        });
    }, []);
*/
    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-107 right-0 bg-cream_300 h-100 absolute">
            <div
                className={`${bgColor} w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative`}>
                    <div>
                    <Header text={"How are you feeling today?"} icon={"right"}/>

                    </div>

                {loading ? (
                   <PageLoader/>
                ) : (
                    <div className="h-57% flex flex-col justify-center items-center">
                        <EmojiSec name={iconName}/>

                        <p className="mt-10% mt-1/3 text-DarkText3 font-semibold text-16">
                            {feel}
                        </p>

                    </div>
                )}

                <div className="App  absolute bottom-1/4">
                    <Slider
                        className="temperature-slider"
                        onchangeSlider={onchangeSlider}
                        value={value}
                    />
                </div>
                <HelpButton
                    className={"absolute bottom-24p left-24p right-24p !w-auto"}
                    title={"Next"}
                    isLoading={nextBtnLoading}
                    onClick={onNextButtonClick}
                />
            </div>
        </div>
    );
};

export default MoodScreen;
