import React, {createContext} from "react";

const ResultContext = createContext();

export const ResultProvider = ({children}) => {
    return (
        <ResultContext.Provider value={{}}>
            {children}
        </ResultContext.Provider>
    )
}
export function ResultUseContext() {
    return React.useContext(ResultContext);
}
