import Slider from "../../home/components/atom/Slider";
import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode} from "swiper/modules";
import Icon from "../../../components/atom/Icon";
import BASE_URL from "../../../../utility/constant";

const PodcastSlider = ({onItemClick, categories}) => {
    const boxShadowStyle = {
        boxShadow:
            "1px 2px 2px 0px rgba(66, 72, 116, 0.1), -1px -2px 2px 0px rgba(66, 72, 116, 0.1)",
    };

    const className =
        `!h-80  rounded-2xl  pb-4 px-24  item-center !flex flex-col justify-evenly items-center text-center mt-[12px]  first:ml-24p`;


    return (
        <div className={"w-full flex flex-col  bg-orange/[0.06]  pt-24p pb-24p"}>
            <p className={"text-DarkText font-medium text-16 pl-24p leading-19"}>Podcast Categories</p>
            <div>
                <Swiper
                    slidesPerView={3.7}
                    spaceBetween={12}
                    freeMode={true}
                    pagination={false}
                    modules={[FreeMode]}
                    className={`mySwiper2`}
                >
                    {categories.map((item, index) => (
                        <SwiperSlide
                            onClick={() => {
                                onItemClick(item.id, item);
                            }}
                            key={index} // Add key prop here
                            className={`bg-white cursor-pointer text-blue font-semibold ${className} ${item.isActive ? "text-orange" : ""}`}
                            style={boxShadowStyle}>
                            {/*<Icon*/}
                            {/*    iconName={item.iconName}*/}
                            {/*    className={"justify-center items-center"}/>*/}

                            {/*<img*/}
                            {/*    className={"justify-center items-center w-[35px] h-[35px]"}*/}
                            {/*    src={BASE_URL + item.icon}*/}
                            {/*/>*/}
                            <p className="text-12">{item.name}</p>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        </div>
    );

}
export default PodcastSlider
