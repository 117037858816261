import React from "react";
import line from "../../../assets/photo/line 4.png";

const Seprator = () => {
  return (
    <div className="">
      <img src={line} alt="" />
    </div>
  );
};

export default Seprator;
