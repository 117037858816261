const Input = ({onChange, placeHolder, disabled, value,className,type}) => {
    return (<input
            className={`bg-input rounded-lg text-DarkText3 h-36 w-full text-10 font-medium outline-none 
            placeholder-DarkText3 ${className} pl-[16px]`}
            placeholder={placeHolder}
            onChange={onChange}
            type={type}
            disabled={disabled}
            value={value}
            required={true}
        />
    )
}
export default Input;
