import React, { useState, useEffect } from "react";
import Header from "../../components/section/Header";
import HelpButton from "../../components/atom/HelpButton";
import Icon from "../../components/atom/Icon";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ResultScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.state);
    const handleHelpButtonClick = () => {
        if (location.state === "RDHome") {
            navigate("/home");
        }
    };
    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-[107px] right-0 bg-cream_300 h-100 absolute">
            <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
                <Header
                    text={"Your result"}
                    iconeSec={"!bg-input"}
                    icon={"blueRight"}
                />
                <p className="pt-16 text-DarkText3 text-12 font-normal px-24 leading-20">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua Egestas
                    purus viverra accumsan in nisl nisi Arcu cursus vitae congue mauris
                    rhoncus aenean vel elit scelerisque In egestas erat imperdiet sed
                    euismod nisi porta lorem mollis Morbi tristique senectus et netus
                    Mattis pellentesque id nibh tortor id aliquet lectus
                </p>
                <p className="pt-16 text-orange text-12 font-normal px-24 leading-20  flex flex-row items-center gap-6">
                    <Icon iconName={"dangerCircle"} />
                    You must see a doctor or get help from the artificial intelligence
                    department
                </p>
                <div className="px-24 pb-24 w-full mt-auto">
                    <HelpButton title={"Done"} onClick={() => handleHelpButtonClick()} />
                </div>
            </div>
        </div>
    );
};

export default ResultScreen;
