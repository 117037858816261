import React, { useState } from "react";
import PodcastBox from "../atom/PodcastBox";
import Icon from "../../../../components/atom/Icon";
import Slider from "../atom/Slider";


const PodcastSec = ({ sectionBox, onViewAllPodcastClick, handleItemClick,podcastArrey }) => {
 

  const items = podcastArrey.map((podcast, index) => ({
    text: podcast.name,
    id: podcast.id,
    iconName: "play2",
    audioSrc: podcast.file
  }));
console.log("###################")
console.log(items)
  

  const classSlider =
    "rounded-xl bg-white  text-10 font-semibold text-blue !h-36 !flex items-center justify-center";

  return (
    <div className={sectionBox}>
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-row justify-center items-center w-fit gap-4 text-DarkText3 text-12 font-semibold mb-27">
          <Icon iconName={"vector"} />
          <span>Podcast</span>
        </div>
        <button
          className="text-blue text-10 font-medium border-b-1 border-solid border-orange-700"
          onClick={onViewAllPodcastClick}
        >
          view all
        </button>
      </div>

      <div className="-mr-16">
        <Slider
          className={classSlider}
          slideNumber={2.9}
          IconProp={true}
          play={true}
          items={items}
          textClass={"whitespace-nowrap overflow-y-hidden "}
          onItemClick={(item)=>handleItemClick(item)}
        />
      </div>

     
    </div>
  );
};

export default PodcastSec;
