import {HomeUseStateContext} from "../home/HomeContext";
import {PodcastUseStateContext} from "./PodcastContext";
import Icon from "../../components/atom/Icon";
import Seprator from "../../components/atom/Seprator";
import React, {useState} from "react";
import PodcastSlider from "./components/PodcastSlider";
import PodcastListItem from "./components/PodcastListItem";
import {useNavigate} from "react-router-dom";
import PageLoader from "../../components/loading/PageLoader";
import BASE_URL from "../../../utility/constant";

const PodcastScreen = () => {
    const {
        podcastList,
        pageLoading,
        onCategoryItemClick,
        podcastCategoryItems,
        filteredPodcastList
    } = PodcastUseStateContext();
    const navigate = useNavigate();
    const handlePlayClick = (podcast) => {
        navigate(`/home/podcast/play?id=${podcast.id}`);
    };
    let content;
    console.log("PAGE LOADER", pageLoading);
    if (pageLoading) {
        content = (<PageLoader/>)
    } else {
        content = (
            <>
                <PodcastSlider
                    onItemClick={onCategoryItemClick} categories={podcastCategoryItems}/>
                <div className="border-b-166 border-solid border-cream_300 h-100 mt-16">
                    <div className="flex flex-col pl-24p pr-[34px] h-100 overflow-y-scroll last:mb-[600px]">
                        {filteredPodcastList.map((podcast, index) => {
                            return (
                                <PodcastListItem
                                    key={index}
                                    title={podcast.name}
                                    description={podcast.description}
                                    imgSrc={BASE_URL + podcast.cover}
                                    onPlayClick={() => handlePlayClick(podcast)}
                                />
                            )
                        })}
                    </div>
                </div>

            </>)
    }

    return (
        <>
            <div className="pt-88 left-0 pb-107 right-0 bg-cream_300 h-100 absolute overflow-y-hidden">
                {content}
            </div>
        </>
    );
};
export default PodcastScreen;
