import HelfinityModal from "../../components/modals/HelfinityModal";
import Icon from "../../components/atom/Icon";
import logo from "../../../assets/photo/group.png";
import React, {useState} from "react";
import Input from "../../components/atom/Input";
import HelpButton from "../../components/atom/HelpButton";
import {MainUseStateContext} from "../main/MainContext";
import toast from "react-hot-toast";

const PassModal = ({isOpen, onClose, onBtnClick}) => {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")

    return (
        <HelfinityModal isOpen={isOpen} onClose={onClose}>
            <div
                className={
                    "flex flex-col justify-center h-100 items-center w-full px-[24px] pt-[40px] pb-[30px]"
                }>
                <div className="flex flex-row  items-center">
                    <img src={logo} alt=""/>
                    <span className="text-16 font-semibold text-DarkText -ml-3 mb-4">
            HelpFinity
          </span>
                </div>
                <p className={"text-DarkText3 text-12 mt-[12px]  font-semibold "}>
                    change password
                </p>

                <Input
                    placeHolder={"Current Password"}
                    className={"mt-[50px]"}
                    onChange={(event) => {
                        setCurrentPassword(event.target.value)
                    }}
                />
                <Input
                    placeHolder={"New Password"}
                    className={"mt-[24px]"}
                    onChange={(event) => {
                        setNewPassword(event.target.value)
                    }}
                />
                <Input
                    placeHolder={"Repeat Password"}
                    className={"mt-[24px]"}
                    onChange={(event) => {
                        setRepeatPassword(event.target.value)

                    }}
                />


                <HelpButton
                    title={"Change password"}
                    className={"mt-[auto]"}
                    onClick={() => {
                        if (repeatPassword === newPassword) {
                            onBtnClick(currentPassword, newPassword, repeatPassword);

                        } else {
                            toast.error("New passwords don't match!");
                        }
                    }}
                />
            </div>
        </HelfinityModal>
    );
};

export default PassModal;
