import React, { useState } from "react";
import Header from "../../components/section/Header";
import Image1 from "../../../assets/photo/1.png";
import Image2 from "../../../assets/photo/2.png";
import Image3 from "../../../assets/photo/3.png";
import Image4 from "../../../assets/photo/4.png";
import Image5 from "../../../assets/photo/5.png";
import HelpButton from "../../components/atom/HelpButton";
import { BreathUseStateContext } from "./BreathContext";
import { useLocation } from "react-router-dom";
import BreathDurationModal from "./BreathDurationModal";  // Import the modal component

const Breath = () => {
    const location = useLocation();
    const {
        onBreathBtnClick,
        countDownTimer,
        breathingStringTitle,
        breathState,
        breathBtnTitle,
        numberOfBreathing,
        totalNumberOfBreathing,
        btnLoading
    } = BreathUseStateContext();

    const [isModalOpen, setModalOpen] = useState(false);

    let breathClassName = "";
    let btnTitle = "";

    let breathCircleClassName = "";

    if (breathState === 1) {
        breathClassName = "breathIn";
        breathCircleClassName = "breathe-in-circle";
    } else if (breathState === 2) {
        breathClassName = "breathStop";
        breathCircleClassName = "hold-circle";

    } else if (breathState === 3) {
        breathClassName = "breathOut";
        breathCircleClassName = "breathe-out-circle";
    } else {
        breathClassName = "breathStop";
        breathCircleClassName = "breathe-stop-circle";
    }

    const handleStartClick = () => {
        setModalOpen(true);
    };

    const handleSelectDuration = (duration) => {
        setModalOpen(false);
        // You can now start the breathing exercise based on the selected duration
        onBreathBtnClick({ duration });
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-107 right-0 bg-cream_300 h-100 absolute">
            <div
                className={`bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative`}>
                <Header text={"Breath"} iconeSec={"!bg-input"} icon={"blueRight"}/>
                <div className="relative w-full h-100 flex flex-col-reverse">
                    <img className={breathClassName} src={Image5} alt=""/>
                    <img className={breathClassName} src={Image4} alt=""/>
                    <img className={breathClassName} src={Image3} alt=""/>
                    <img className={breathClassName} src={Image2} alt=""/>
                    <div
                        className={`flex flex-col w-157 h-157 bg-white text-orange absolute rounded-full top-1/2 left-1/2 
             transform -translate-x-1/2 -translate-y-1/2 justify-center items-center text-16 font-semibold 
             ${breathCircleClassName}`}>
                        <p className="text-16">{countDownTimer}</p>
                        <p className="text-12">{breathingStringTitle}</p>
                    </div>
                </div>
                <div className="px-24 pb-24 w-full">
                    <HelpButton
                        onClick={handleStartClick}  // Open the modal when clicking the Start button
                        isLoading={btnLoading}
                        className={"h-40 "}
                        title={breathBtnTitle}
                    />
                </div>
            </div>

            {/* Modal */}
            <BreathDurationModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSelectDuration={handleSelectDuration}
            />
        </div>
    );
};

export default Breath;
