export const BehaviorInitState = {
    pageLoading: false,
    items: [],
    showAddNewItemForm: false,
    seeResultLoading: false,
    addCustomLoading: false
}
export const BehaviorReducer = (state, action) => {
    switch (action.type) {
        case "SET_ITEMS":
            return {
                ...state, items: action.payload.map((item) => {
                    return {...item, "isActive": false}
                })
            };

        case "CHANGE_ANSWER":
            return {
                ...state, items: state.items.map((item) => {
                    if (item.id === action.payload.id) {
                        return {...item, isActive: !item.isActive}
                    } else {
                        return item
                    }
                })
            };

        case "ADD_CUSTOM_ANSWER":
            return {
                ...state, questionOptions: state.items.push(action.payload)
            }
        case "CHANGE_FORM_VISIBILITY":
            return {...state, showAddNewItemForm: !state.showAddNewItemForm};
        case "CHANGE_ADD_CUSTOM_LOADING":
            return {...state, addCustomLoading: !state.addCustomLoading};

        case "CHANGE_RESULT_LOADING":
            return {...state, seeResultLoading: !state.seeResultLoading};

        case "CHANGE_PAGE_LOADING":
            return {...state, pageLoading: !state.pageLoading};

    }
}