import axiosInstance from "../../services/remote/axios";
import HelpfinityTryCatch from "../../utility/Helper";


class UserRepository {

    async login({email, password}) {

        try {
            // console.log("BEFORE REQUEST..............");
            const response = await axiosInstance.post("/accounts/login", {
                email: email,
                password: password,
            })
            // console.log("REPOSITORY RESPONSE  : ", response);
            const accessToken = response.data.data.access_token;
            const refreshToken = response.data.data.refresh_token;

            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('access_token', accessToken);
            return {success: true, message: 'Logged in successfully'};

        } catch (e) {
            return {success: false, message: e.message};
        }


    }

    async register({email, password}) {
        try {
            const response = await axiosInstance.post("accounts/register", {
                email: email,
                password: password,
            })
            const accessToken = response.data.data.access_token;
            const refreshToken = response.data.data.refresh_token;

            localStorage.setItem('refresh_token', refreshToken);
            localStorage.setItem('access_token', accessToken);

            return {success: true, message: 'Logged in successfully'};
        } catch (e) {
            return {success: false, message: e.message};
        }


    }

    async sendActivationCode() {
        try {
            const response = await axiosInstance.get("accounts/activation",)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async verifyActivationCode() {
        ///Meti What is Body???????

        try {
            const response = await axiosInstance.post("accounts/verification",)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async accountOverview() {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.get("accounts/overview")

            }
        )
    }

    async editProfile(username, email) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.patch('accounts/profile', {
                    "username": username,
                    "email": email,

                });

            }
        )

    }

    async changePassword(data) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post('accounts/change-pass', data);

            }
        )
    }

    async validateUser() {
        try {
            const response = await axiosInstance.get("accounts/overview",)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async getProfile() {
        try {
            const response = await axiosInstance.get("accounts/overview",)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async refreshToken({refreshToken}) {
        try {
            const response = await axiosInstance.post("accounts/refresh", {
                "refreshToken": refreshToken
            })

            return response.data.data;
        } catch (e) {

        }
    }

    async refreshToAccess({refreshToken}) {

        try {
            const response = await axiosInstance.post("accounts/refresh-access", {
                    "refreshToken": refreshToken
                }
            )
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }

    async updateProfilePhoto(photoFile) {
        return await HelpfinityTryCatch(
            function () {
                return axiosInstance.post('accounts/profile', {
                    "photo": photoFile
                }, {headers: {'Content-Type': 'multipart/form-data',},});

            }
        )
    }

    async logout() {
        ///Meti REMOVE TOKENS
        try {
            const response = await axiosInstance.get("accounts/logout",)
            console.log("RESPONSE : ", response);
            return response.data.data;
        } catch (e) {

        }
    }


}

export default UserRepository;