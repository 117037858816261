import React, {useState, createContext} from "react";
import userRepository from "../../../repository/user/UserRepository";
import UserRepository from "../../../repository/user/UserRepository";
import toast from "react-hot-toast";

const ProfileContext = createContext();
const profileInitState = {
    "id": null,
    "username": null,
    "email": null,
    "first_name": null,
    "last_name": null,
    "created_at": null,
    "updated_at": null,
    "is_active": true,
    "is_superuser": true,
    "email_verified": false,
    "photo": "../"
};

const ProfileProvider = ({children}) => {
    const baseUrl = "http://5.161.144.222:8000";
    const [isOpen, setisOpen] = useState(false)
    const [profileData, setProfileData] = useState(profileInitState);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null)
    const [error, setError] = useState("")
    const [image, setImage] = useState(null)
    const userRepository = new UserRepository();

    const initState = async () => {
        const response = await userRepository.accountOverview();
        if (response.success) {
            const user = response.data.user;
            setProfileData(user);
            setProfileImage(baseUrl + response.data.user.photo);
        } else {

            setProfileData({});

            setError(response.message);

        }
    };
    const handleFileUpload = async (e) => {
        console.log("ON BTN CLICK");

        const file = e.target.files[0];
        const response = await userRepository.updateProfilePhoto(file);
        if (response.success) {
            console.log("SUCCESSSS");
            setProfileImage(baseUrl + response.data.photo);


        } else {
            console.log("NOT");

        }
    };
    const saveBtnOnClick = async (email, userName) => {
        setSaveBtnLoading(true)
        const response = await userRepository.editProfile(userName, email);
        setSaveBtnLoading(false)
        if (response.success) {
            setProfileData(prevState => ({
                ...prevState, email: email, username: userName, first_name: "",
            }))
        } else {

            setError(response.message);
        }
    }
    const onChangePasswordClick = async (currentPassword, newPassword, repeatPassword) => {
        const data = {
            oldpass: currentPassword,
            newpass: newPassword
        }
        const response = await userRepository.changePassword(data);
        if (response.success) {
            toast.success(response.message);
        } else {

        }

    }
    const changePass = () => {
        setisOpen(true)
    };
    const onClose = () => {
        setisOpen(false)
    }

    return (<ProfileContext.Provider
        value={{
            initState,
            changePass,
            isOpen,
            onClose,
            saveBtnOnClick,
            profileData,
            profileImage,
            saveBtnLoading,
            onChangePasswordClick,
            handleFileUpload
        }}>
        {children}
    </ProfileContext.Provider>);
};

export default ProfileContext;
export {ProfileProvider};
