import React from "react";
import Header from "../../components/section/Header";
import HelpButton from "../../components/atom/HelpButton";
import {DepressionUseContext} from "./DepressionContext";
import PageLoader from "../../components/loading/PageLoader";

const DepressionScreen = () => {
    const {answers, loading, onItemClick, onDoneClick, buttonLoading} = DepressionUseContext();

    function getSelectedAnswerColumns(index) {
        return answers.filter((item) => item.level === index);
    }

    const createColumn = (index) => {
        return (<div className={"flex flex-col gap-[6px]"}>
            {
                getSelectedAnswerColumns(index).map((item, index) => {
                    return <button
                        key={index}
                        className={`w-full text-center min-h-40  rounded-2x px-20 rounded-2xl
                         text-DarkText3 text-10 font-medium ${item.isActive ? "bg-blue_200" : "bg-cream_300"}`}
                        onClick={() => onItemClick(item)}>
                        {item.title}
                    </button>;
                })

            }
        </div>)

    }
    let content;
    if (loading) {
        content = (<PageLoader/>)
    } else {
        content = (
            <>

                <p className="pt-16 px-24 text-center text-DarkText3 text-12 font-medium">
                    Which of the following symptoms do you experience?
                </p>
                <div className="h-100 flex items-center justify-center">
                    <div className={"flex flex-row justify-between gap-[6px] px-12"}>
                        {createColumn(1)}
                        {createColumn(2)}
                        {createColumn(3)}
                    </div>
                </div>
                <div className="px-24 pb-24 w-full mt-auto">
                    <HelpButton
                        title={"Next"}
                        onClick={onDoneClick}
                        isLoading={buttonLoading}
                    />
                </div>
            </>

        )

    }

    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-24 right-0 bg-cream_300 h-100 absolute">
            <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
                <Header
                    text={"Assessment of depression level"}
                    iconeSec={"!bg-input"}
                    icon={"blueRight"}
                />
                {content}

            </div>
        </div>
    );
};

export default DepressionScreen;
