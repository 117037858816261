import React, {useContext, useEffect, useRef, useState} from "react";
import ProfileContext from "./ProfileContext";
import Icon from "../../components/atom/Icon";
import Seprator from "../../components/atom/Seprator";
import Input from "../../components/atom/Input";
import HelpButton from "../../components/atom/HelpButton";
import PassModal from "./PassModal";

const ProfileScreen = () => {
    const {
        name,
        initState,
        changePass,
        isOpen,
        onClose,
        profileData,
        profileImage,
        saveBtnOnClick,
        onChangePasswordClick,
        handleFileUpload,
        saveBtnLoading
    } =
        useContext(ProfileContext);

    useEffect(() => {
        initState();
    }, []);
    const updateTime = `${profileData.updated_at == null ? "" : `Last update time : ${profileData.updated_at}`}`;
    const [email, setEmail] = useState("")
    const [userName, setUserName] = useState()
    const inputFile = useRef(null);
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const valueOfPictureInput=null;

    return (
        <div className="absolute  left-0 right-0 pt-88 pl-24 pr-24 pb-107 bg-cream_300 h-100 ">
            <div
                className="flex flex-col justify-between items-center bg-white w-full h-100 rounded-3xl shadow-blur1 p-24">
                <div className={"flex flex-row justify-between items-center w-full"}>
                    <p className={"tex-16 font-medium text-DarkText3"}>My Profile</p>
                    <p className={"text-10 font-medium text-DarkText3 "}>
                        {updateTime}
                    </p>
                </div>
                <div className="flex flex-col justify-center w-full items-center">
                    <div
                        className={`relative content-center  w-[100px] h-[100px] bg-profileIconBg rounded-full my-[25px]`}>
                        {
                            profileImage ? (
                                /*Until Image Download*/
                                <img
                                    src={profileImage}
                                    alt="Selected"
                                    className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full"}
                                />
                            ) : (
                                <Icon
                                    iconName={"profile-img"}
                                    className={
                                        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    }
                                />
                            )
                        }

                        <Icon
                            iconName={"edit-profile"}
                            className={"absolute bottom-0 left-0"}
                            onClick={() => {
                                onButtonClick();
                                console.log("ON Edit Profile Click");
                            }}
                        />
                        <input
                            value={''}
                            className={"hidden"}
                            ref={inputFile}
                            onChange={handleFileUpload}
                            type="file"
                            accept={"image/*"}

                        />
                    </div>
                    <Input
                        placeHolder={profileData.username ? "Your user name" : "Enter your username : "}
                        value={profileData.username ?? userName}
                        onChange={(event) => {
                            setUserName(event.target.value);
                        }}
                    />
                    <Input
                        placeHolder={"Your email address"}
                        value={profileData.email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        className={"my-[12px]"}
                    />
                    <Input
                        placeHolder={"Registeration time"}
                        value={profileData.created_at}
                        disabled={true}

                    />
                </div>

                <div className=" w-full text-center">
                    <HelpButton title={"Save"}
                                isLoading={saveBtnLoading}
                                onClick={() => {
                                    saveBtnOnClick(email, userName);

                                }}/>
                    <span
                        onClick={changePass}
                        className={"text-blue text-10 font-medium self-center mt-8"}>
                        change your password
                    </span>
                </div>

            </div>
            <PassModal isOpen={isOpen} onClose={onClose} onBtnClick={onChangePasswordClick}/>
        </div>
    );
};
export default ProfileScreen;
