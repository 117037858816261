// import { createRoot } from 'react-dom/client'
import { ReactSVG } from 'react-svg'

const Icon = ({ iconName, className ,onClick ,data,svgClass}) => {
  
  try {
    let iconel = undefined;

    iconel = require(`../../../assets/photo/svg/${iconName}.svg`);

    return (
      <span className={`${className} flex `} onClick={onClick} data-attr={data}>
        <ReactSVG src={iconel} className={svgClass}  />
      </span>
    );
  } catch {
    console.log("Invalid Icon Name or Icon Path :(");
    return <span>can not load image</span>;
  }
};

export default Icon;
