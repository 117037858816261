import {useReducer} from "react";

export const mainReducer = (state, action) => {
    switch (action.type) {
        case "SET_REMINDER_LIST":
            return {...state, reminderList: action.payload};
        case "SET_HAS_REMINDER":
            return {...state, hasReminder: action.payload};
        case "SET_LIST_OF_DAYS":
            return {...state, listOfDays: action.payload};
        case "REMINDER_BTN_LOADING":
            return {...state, addReminderLoading: action.payload};
        case "REMINDER_GET_LOADING":
            return {...state, getRemindersLoading: action.payload};
        case "SET_REMINDER_TIME":
            return {...state, selectedTime: action.payload};
        case "LOGIN_LOADING":
            return {...state, loginLoading: action.payload};
        case "SIGNUP_LOADING":
            return {...state, signupLoading: action.payload};
        case "USER_LOGIN":
            return {...state, isUserLogin: action.payload};
        case "CHANGE_REMINDER":
            return {
                ...state,
                reminderList: state.reminderList.map((reminder) => {
                    if (action.payload.id === reminder.id) {
                        return {...reminder, isActive: !reminder.isActive};
                    } else {
                        return reminder;
                    }
                }),
            };
        case "DELETE_REMINDER":
            return {
                ...state,
                reminderList: state.reminderList.filter(reminder => reminder.id !== action.payload.id),
            };

        default:
            throw new Error("Unknown action type");
    }
};
export const mainInitState = {
    reminderList: [],
    listOfDays: [],
    addReminderLoading: false,
    getRemindersLoading: false,
    selectedTime: "",
    isUserLogin: false,
    signupLoading: false,
    loginLoading: false,
    hasReminder: false,
};

