import HelfinityModal from "./HelfinityModal";
import Icon from "../atom/Icon";

const MoodTrackModal = ({
  isOpen,
  onClose,
  onEmotionClick,
  onBehaviorClick,
}) => {
  // console.log(isOpen);
  if (!isOpen) return null;

  const isClosable = true;
  return (
    <div
      className={`fixed flex top-0 left-0 bottom-0 right-0 
             w-full h-full bg-blur_200 z-10`}>
      <div
        className={`absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2
                 flex flex-col  rounded-[24px]
                 justify-start items-center z-20`}>
        <div
          onClick={onEmotionClick}
          className={`
                    border-1 border-blue bg-white w-[312px] rounded-[32px] flex flex-row justify-between items-center p-[36px] 
                    `}>
          <p className="text-16 text-DarkText3 font-semibold">Emotion</p>
          <Icon iconName={"emotion-image"} />
        </div>
        <div
          onClick={onBehaviorClick}
          className={`border-1 border-blue bg-white w-[312px]  rounded-[32px] 
                    flex flex-row justify-between items-center mt-24 p-[36px] `}>
          <p className="text-16 text-DarkText3 font-semibold">Behavior</p>
          <Icon iconName={"behavior-image"} />
        </div>
      </div>
      <div
        className={
          "absolute bg-blur w-full h-full flex justify-center items-center z-10 backdrop-blur-[2px]"
        }
        onClick={isClosable && onClose}
      />
    </div>
  );
};
export default MoodTrackModal;
