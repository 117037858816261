import React, {createContext, useEffect, useRef, useState} from "react";
import AiReferRepository from "../../../repository/ai/AiReferRepository";
import reminderItem from "../reminder/Components/ReminderItem";
import {useNavigate} from "react-router-dom";
const AiContext = createContext();

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const AiProvider = ({children}) => {
    const navigate = useNavigate();
    const aiRepo = new AiReferRepository()
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [questionList, setQuestionList] = useState([])
    const [answersList, setAnswersList] = useState([])
    const [questionTotalNumber, setQuestionTotalNumber] = useState(0)
    const [screenLoading, setScreenLoading] = useState(false)


    const initState = async () => {
        setScreenLoading(true)
        const response = await aiRepo.getQuestionList();
        setScreenLoading(false)
        if (response.success) {
            setQuestionTotalNumber(response.data.length);
            const newData = response.data.map((item)=>{
               return {...item,"answers": [
                       "YES",
                       "NO"
                   ]}
            });
            setQuestionList(newData);
        }

    }

    const onNextClick = () => {
        if (currentQuestionIndex + 1 <= answersList.length) {
            setCurrentQuestionIndex(prevState => prevState + 1);
        }
    }
    const onPrevClick = () => {
        if (currentQuestionIndex !== 0) {
            setCurrentQuestionIndex(prevState => prevState - 1);

        }
    }

    const onAnswerClick = (answer) => {
        if (currentQuestionIndex < answersList.length) {
            setAnswersList((prevState) => {
                return prevState.map((item, index) => {
                    if (index === currentQuestionIndex) {
                        return answer;
                    } else {
                        return item;
                    }
                })
            })
        } else if (currentQuestionIndex === answersList.length) {
            setAnswersList(prevState => [...prevState, answer]);
        }
    }
    const onNextScreenClick = () => {
        console.log("done")
        if (answersList.length === questionList.length) {
            const newAnswerList = answersList.map((item, index) => {
                return {"questionNumber": index, "answer": item};
            });
            console.log("^^^^^^^^^^^^^^^^^^")
            console.log(newAnswerList);
            console.log("^^^^^^^^^^^^^^^^^^^^")
            navigate("/ai/result")
            setScreenLoading(true)
            timeout(1000);
            setScreenLoading(false)

        }
    }


    return <AiContext.Provider
        value={{
            initState,
            currentQuestionIndex,
            questionTotalNumber,
            questionList,
            screenLoading,
            onAnswerClick,
            onPrevClick,
            onNextClick,
            onNextScreenClick,
            answersList
        }}>
        {children}
    </AiContext.Provider>
}

function AiUseStateContext() {
    return React.useContext(AiContext);
}

export {AiUseStateContext};
export default AiProvider;