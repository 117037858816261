import React from "react";
import Header from "../../../components/section/Header";
import Icon from "../../../components/atom/Icon";
import { PlayPodcastUseStateContext } from "./PlayPodcastContext";
import PageLoader from "../../../components/loading/PageLoader";
import BASE_URL from "../../../../utility/constant";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";

const PlayPodcastScreen = () => {
  const { pageLoading, selectedPodcast } = PlayPodcastUseStateContext();

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="pt-88 pl-24 pr-24 left-0 pb-107 right-0 bg-cream_300 h-100 absolute">
      <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
        <Header text={"Listen to this podcast"} iconBackground={"bg-input"} />
        <div className="w-full flex flex-col justify-evenly items-center h-100">
          <div className="w-[200px] h-[200px] rounded-full shadow-md bg-gray flex justify-center items-center mt-24">
            <img
              src={BASE_URL + selectedPodcast.cover}
              className={"object-contain block w-1/3  rounded-full "}
              alt=""
            />
          </div>
          <h2 className="">{selectedPodcast.name}</h2>
          <p className="mt-4 text-center px-10">{selectedPodcast.description}</p>
          <AudioPlayer
            src={BASE_URL + selectedPodcast.file}
            className="w-full px-12 pb-12 !bg-none !shadow-none rhap_loop--off"
            onPlay={() => console.log("Playing...")}
            showFilledProgress={false}
            showFilledVolume={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayPodcastScreen;
