import React, { useState } from "react";
import Icon from "../../../components/atom/Icon";
import {useNavigate} from "react-router-dom";
const RoadMap = ({step,openModal}) => {
  // const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const getIconName = () => {
    switch (step) {
      case 1:
        return "line";
      case 2:
        return "lineStep2";
      case 3:
        return "lineStep3";
      case 4:
        return "lineStep4";
      default:
        return "line";
    }
  };

  const textActive = "w-auto text-10 font-medium p-9 rounded-xl bg-orange text-white";
  const textBefore = "w-auto text-10 font-medium pl-16 pb-9 pr-11 pt-9 text-darkBlue";
  const textDeactive = "w-auto text-10 font-medium pl-16 pb-9 pr-11 pt-9 text-gray_100";

  return (
    <div className="journeyScreen flex flex-col gap-35 justify-center">
      <div className="relative">
        <Icon
          iconName={getIconName()}
          className={"w-full px-68"}
          svgClass={"w-full roadMap"}
        />

        <p className="-mt-14 absolute top-0 flex items-center w-full gap-4">
          <span
            className={`
              ${step === 1 ? textActive : step > 1 ? textBefore : textDeactive}
            `}
            onClick={openModal}
          >
            1. Mood track yourself
          </span>
          <span className={`${step === 1 ? "w-16 h-16 rounded-full bg-darkBlue" : "none"}`}></span>
        </p>
        <p className="absolute top-[31%] flex items-center w-full gap-4">
          <span
            className={`
              ${step === 2 ? textActive : step > 2 ? textBefore : textDeactive}
            `}
            onClick={()=>{navigate("/breath",{state:"RDJOUR"})}}
          >
            2. Breath
          </span>
          <span className={`${step === 2 ? "w-16 h-16 rounded-full bg-darkBlue mt-6" : "none"}`}></span>
        </p>
        <p className="absolute top-[54%]  flex items-center w-full justify-end gap-4 right-10p ">
          <span className={`${step === 3 ? "w-16 h-16 rounded-full bg-darkBlue" : "none"}`}></span>
          <span
            className={`
              ${step === 3 ? textActive  : step > 3 ? textBefore : textDeactive}
            `}
            onClick={()=>{navigate("/thought",{state:"RDJOUR"})}}
          >
            3. Calm
          </span>
        </p>
        <p className="absolute top-[76%] flex items-center w-full gap-4">
          <span
            className={`
              ${step === 4 ? textActive + " -ml-11" : step > 4 ? textBefore : textDeactive +" -ml-4"}
            `}
            onClick={()=>{navigate("/home/podcast")}}
          >
            4. Podcast
          </span>
          <span className={`${step === 4 ? "w-16 h-16 rounded-full bg-darkBlue" : "none"}`}></span>
        </p>
      </div>
    </div>
  );
};

export default RoadMap;
