import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";

import {FreeMode} from "swiper/modules";

const JourneySlider = ({items, className, boxShadowStyle, slideNumber, onItemClick}) => {
    return (
        <div>
            <Swiper
                slidesPerView={slideNumber}
                spaceBetween={12}
                freeMode={true}
                pagination={false}
                modules={[FreeMode]}
                className={`mySwiper2`}
            >
                {items.map((item, index) => (
                    <SwiperSlide
                        onClick={() => {
                            onItemClick(item);
                            // onItemClick(item.route);
                        }}

                        key={index} // Add key prop here
                        className={`bg-white cursor-pointer text-blue font-semibold ${className} ${item.isActive ? "text-orange" : ""}`}
                        style={boxShadowStyle}
                    >
                        <p className="text-10">{item.date}</p>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default JourneySlider;
