import React, {useState, useEffect} from "react";
import Header from "../../components/section/Header";
import HelpButton from "../../components/atom/HelpButton";
import {useLocation, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Toaster} from "react-hot-toast";
import {EmotionUseContext} from "./EmotionContext";
import PageLoader from "../../components/loading/PageLoader";

const EmotionSection = () => {
    const {
        onAnswerClick,
        question,
        answers,
        pageLoading,
        nextBtnLoading,
        selectedAnswer,
        param,
        onNextBtnClick,
        onDoneBtnClick,
        doneBtnLoading
    } = EmotionUseContext();
    let content;
    if (pageLoading) {
        content = <PageLoader/>
    } else {
        content = answers.map((item, index) => {

            return (
                <button
                    key={item.id}
                    onClick={() => {
                        onAnswerClick(item);
                    }}

                    className={`w-full text-center py-12 rounded-lg text-DarkText3 text-10 font-medium ${
                        item.isActive ? "bg-blue_200" : "bg-cream_300"}`}

                >
                    {item.title}
                </button>
            )
        })
    }
    return (
        <div className="pt-88 pl-24 pr-24 left-0 pb-24 right-0 bg-cream_300 h-100 absolute">
            <div className="bg-white w-full h-100 rounded-3xl shadow-blur1 flex flex-col items-center relative">
                <Header text={"Emotion"} iconeSec={"!bg-input"} icon={"blueRight"}/>
                <p className="pt-16 text-DarkText3 text-12 font-medium">{question}</p>
                <div className="grid grid-cols-2 gap-12 w-full px-24 pt-16">
                    {content}

                </div>
                <div className="px-24 pb-24 w-full mt-auto">
                    {param === "effect" ? (
                        <HelpButton title={"Done"} onClick={onDoneBtnClick} isLoading={doneBtnLoading}/>
                    ) : (
                        <HelpButton
                            title={"Next"}
                            isLoading={nextBtnLoading}
                            onClick={() => onNextBtnClick()}
                        />
                    )}
                </div>
            </div>
            <Toaster toastOptions={{duration: 1000}}/>
        </div>
    );
};

export default EmotionSection;
