import React, {createContext, useEffect, useState} from 'react';
import {MainUseStateContext} from "../main/MainContext";
import Cookies from "js-cookie";
import HomeRepository from "../../../repository/home/homeRepository";
const HomeContext = createContext();

export const HomeProvider = ({children}) => {
    // const userRepository = new UserRepository();
    const podcasts = new HomeRepository()
    const [data, setData] = useState("Welcome to Home Page");
    const [isModalShow, setIsModalShow] = useState(false)
    const {mainDispatch} = MainUseStateContext();
    const [podcastArrey, setpodcast] = useState([])

    const onInitialState = async () => {
        const refreshToken = localStorage.getItem("refresh_token")
        const access = localStorage.getItem("access_token")
        if (!access || !refreshToken) {
            console.log("asdasdasdadassd");
            mainDispatch({type: "USER_LOGIN", payload: false});
        }
        Cookies.remove("HTTP_ACCESS")

        console.log("INIT STATE CALLL Home")
        // await userRepository.login({email: "2@2.com", password: "2"});
        // await userRepository.accountOverview();
        const response = await podcasts.podcasts()
        console.log(response.data)
        setpodcast(response.data)

    }
    useEffect(() => {
        onInitialState();
    }, []);
    const openModal = () => {
        setIsModalShow(true);
    }
    const closeModal = () => {
        setIsModalShow(false);
    }
    return (
        <HomeContext.Provider value={{data, onInitialState, openModal, closeModal, isModalShow,podcastArrey}}>
            {children}
        </HomeContext.Provider>
    );

};

function HomeUseStateContext() {
    return React.useContext(HomeContext);
}

export {HomeUseStateContext};
export default HomeProvider;
