import React, {createContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ReportRepository from "../../../repository/report/ReportRepository";

const AnxietyContext = createContext();

export const AnxietyProvider = ({children}) => {

    const navigate = useNavigate();
    const reportRepository = new ReportRepository();
    const [loading, setLoading] = useState(true)
    const [buttonLoading, setButtonLoading] = useState(false)

    const [answers, setAnswers] = useState([])

    const onInitState = async () => {
        setLoading(true)
        const response = await reportRepository.getAnxietyAnswers();
        setLoading(false)
        if (response.success) {
            const insertIsActiveList = response.data.map((item, index) => {
                return {...item, "isActive": false}
            })
            setAnswers(insertIsActiveList);

        }else{
            ///ERROR
        }


    }
    useEffect(() => {
        onInitState();
    }, []);


    const onItemClick = (item) => {
        setAnswers(prevState => {
            return prevState.map((prevItem) => {
                if (item.id === prevItem.id) {
                    return {...item, isActive: !item.isActive}
                } else {
                    return prevItem;
                }

            })
        })
    }
    const onDoneClick = async () => {
        const selectedAnswers = answers.filter((item) => item.isActive)
            .map(({isActive, ...keep}) => keep)
        let totalNumberOf2 = 0;
        let hasLevel3 = false;
        selectedAnswers.forEach(function (value) {
            if (value.level === 3) {
                hasLevel3 = true;
            } else if (value.level === 2) {
                totalNumberOf2++;
            }
        })
        setButtonLoading(true);
        const response = await reportRepository.sendAnxietyAnswer(selectedAnswers);
        setButtonLoading(false);
        if (response.success) {
            if (totalNumberOf2 === 2 || hasLevel3) {
                navigate("/result", {state: "RDHome"})
            } else {
                navigate("/breath", {state: "RDCalm"})

            }
        }

    }


    return (
        <AnxietyContext.Provider value={{answers, loading, onInitState, onItemClick, onDoneClick, buttonLoading}}>
            {children}
        </AnxietyContext.Provider>)
}

export function AnxietyUseContext() {
    return React.useContext(AnxietyContext);

}