import React from "react";

const AnswerButton = ({title, onClick, currentAnswer}) => {
    // console.log("CURRENT ANSWER : ", currentAnswer);
    // console.log("TITLE : ", title);
    const isSelected = currentAnswer === title;
    let btnClassName = "";
    let lowerTitle = title.toLowerCase().trim();
    if (lowerTitle === "yes") {
        btnClassName = "h-72 w-[40%]   font-semibold text-12 w-full rounded-2xl shadow-blur2";
    } else if (lowerTitle === "no") {
        btnClassName = "h-72 w-[40%]  font-semibold text-12 w-full rounded-2xl shadow-blur2";
    } else {
        btnClassName = "h-72 w-[40%]  font-medium text-10  w-full rounded-2xl shadow-blur2";
    }
    return (
        <button
            onClick={onClick}
            className={`${btnClassName} ${isSelected ? "bg-blue_200 text-DarkText3" : "bg-cream-300 text-blue_100"}`}>
            {title}
        </button>
    );

}
export default AnswerButton;